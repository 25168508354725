export default {
    data: () => { return { 
        availableLanguages: ["sv","en", "fi"],
        uiLanguage: "en",
        default: "en",
        localise: {
            codeMap: {
                "en": "English",
                "fi": "Suomi",
                "sv": "Svenska"
            },
            "bandwagon": {"en":"Bandwagon","sv":"Bandvagn","fi":"Bändivaunu"}, // game names
            "bilingia": {"en":"Bilingia","sv":"Bilingia","fi":"Bilingia"},
            "anagramia": {"en":"Anagramia","sv":"Anagramia","fi":"Anagramia"},
            "eliminator": {"en":"Eliminator","sv":"Eliminator","fi":"Eliminator"},
            "statistics": {"en":"Statistics","sv":"Statistik", "fi":"Tilastot"},
            "tagline": {"en": "A Word Game","fi":"Sanapeli","sv":"Ett ordspel"},

            "gamesPlayed": {"en":"Games played", "sv":"Ronder totalt", "fi":"Pelatut pelit"},
            "wins": {"en": "Wins", "sv":"Vinster","fi":"Voitot"},
            "losses": {"en": "Losses", "sv":"Förluster","fi":"Tappiot"},
            "fastestSolution": {"en": "Fastest solve", "sv": "Snabbaste lösning","fi":"Nopein ratkaisu"},
            "currentStreak": {"en": "Current streak", "sv": "Vinster på rad", "fi": "Peräkkäiset voitot"},
            "bestStreak": {"en": "Best streak", "sv": "Flest vinster på rad", "fi": "Eniten peräkkäisiä voittoja"},
            "letsPlayAgain": {"en": "Let's do this again!", "sv":"Jag vill spela en gång till!", "fi":"Haluan pelata kerran vielä!"},
            "startNewGame": {"en": "Start new game", "sv":"Starta nytt spel", "fi":"Aloita uusi peli"},
            "thanksForTheInfo": {"en": "Thanks for the info, now let's play!", "sv":"Tack för infon, nu spelar vi!", "fi":"Kiitos tiedosta, nyt pelataan!"},
            "welcomeTo": {
                "bandwagon": {
                    "en": "Welcome to BANDWAGON", "sv": "Välkommen till BANDVAGN", "fi":"Tervetuloa BÄNDIVAUNUUN"
                },
                "bilingia": {
                    "en": "Welcome to BILINGIA", "sv": "Välkommen till BILINGIA", "fi":"Tervetuloa BILINGIALLE"
                },
                "anagramia": {
                    "en": "Welcome to ANAGRAMIA", "sv": "Välkommen till ANAGRAMIA", "fi":"Tervetuloa ANAGRAMIALLE"
                },
                "eliminator": {
                    "en": "Welcome to ELIMINATOR", "sv": "Välkommen till ELIMINATOR", "fi":"Tervetuloa ELIMINATORILLE"
                }
            },
            "hasWon": {
                "en": "GLORIOUS VICTORY",
                "sv": "Vilken fantastisk vinst!",
                "fi": "Mikä mahtava voitto!"
            },
            "lament": {
                "en":"Meh.",
                "sv": "Äsch då.",
                "fi": "Auts."
            },
            "hasLost": {
                "en": "That was somewhat short of success.",
                "sv": "Det räckte inte riktigt hela vägen den här gången.",
                "fi": "Se ei riittänyt tällä kertaa."
            },
            "shortIntro": {
                "bandwagon": {
                    "en": "Bandwagon is a totally original word game. Totally original, yup.",
                    "sv": "Bandvagn är ett unikt ordspel. Jepp, helt unikt.",
                    "fi": "Bändivaunu on ainutlaatuinen sanapeli. Täysin ainutlaatuinen."
                },
                "bilingia": {
                    "en": "Bilingia is a totally original word game. Totally original, yup.",
                    "sv": "Bilingia är ett unikt ordspel. Jepp, helt unikt.",
                    "fi": "Bilingia on ainutlaatuinen sanapeli. Täysin ainutlaatuinen."             
                 },
                "anagramia": {
                    "en": "Anagramia is a totally original word game. Totally original, yup.",
                    "sv": "Anagramia är ett unikt ordspel. Jepp, helt unikt.",
                    "fi": "Anagramia on ainutlaatuinen sanapeli. Täysin ainutlaatuinen." 
                },
                "eliminator": {
                    "en": "Eliminator is a totally original word game. Totally original, yup.",
                    "sv": "Eliminator är ett unikt ordspel. Jepp, helt unikt.",
                    "fi": "Eliminator on ainutlaatuinen sanapeli. Täysin ainutlaatuinen." 
                }
            },
            "intro": {
                "bandwagon": {
                    "en": "BANDWAGON is a totally original word game in no way inspired by other word games that the New York Times is apparently willing to pay \"seven figures\" for. Totally original, yup.",
                    "sv": "BANDVAGN är ett unikt ordspel som inte överhuvudtaget har inspirerats av andra ordspel som New York Times tydligen är villiga att betala \"sjusiffriga\" belopp för. Nepp, helt unikt.",
                    "fi": "BÄNDIVAUNU on ainutlaatuinen sanapeli, joka ei ole lainkaan saanut vaikutteita muista sanapeleistä, joista New York Times on ilmeisesti valmis maksamaan \"seitsennumeroisia\" summia. Ei, täysin ainutlaatuinen."
                },
                "bilingia": {
                    "en": "BILINGIA is a totally original word game in no way inspired by other word games that the New York Times is apparently willing to pay \"seven figures\" for. Totally original, yup.",
                    "sv": "BILINGIA är ett unikt ordspel som inte överhuvudtaget har inspirerats av andra ordspel som New York Times tydligen är villiga att betala \"sjusiffriga\" belopp för. Nepp, helt unikt.",
                    "fi": "BILINGIA on ainutlaatuinen sanapeli, joka ei ole lainkaan saanut vaikutteita muista sanapeleistä, joista New York Times on ilmeisesti valmis maksamaan \"seitsennumeroisia\" summia. Ei, täysin ainutlaatuinen."
                },
                "anagramia": {
                    "en": "ANAGRAMIA is a totally original word game in no way inspired by other word games that the New York Times is apparently willing to pay \"seven figures\" for. Totally original, yup.",
                    "sv": "BANDVAGN är ett unikt ordspel som inte överhuvudtaget har inspirerats av andra ordspel som New York Times tydligen är villiga att betala \"sjusiffriga\" belopp för. Nepp, helt unikt.",
                    "fi": "BÄNDIVAUNU on ainutlaatuinen sanapeli, joka ei ole lainkaan saanut vaikutteita muista sanapeleistä, joista New York Times on ilmeisesti valmis maksamaan \"seitsennumeroisia\" summia. Ei, täysin ainutlaatuinen."
                }
            },
            "rules": {
                "bandwagon": {
                    "en": "Anyway, in BANDWAGON, your task is to find four words. The words are arranged in a rectangle (the horizontal words written left-to-right and the vertical ones top-to-bottom), sharing first and last letters.",
                    "sv": "Hursomhelst, din uppgift i BANDVAGN är att hitta fyra ord. Orden formar en fyrkant (de horisontella orden är skrivna från vänster till höger, och de vertikala från toppen till botten), och de delar första och sista bokstäverna.",
                    "fi": "Joka tapauksessa, tehtäväsi BÄNDIVAUNUssa on löytää neljä sanaa. Sanat muodostavat neliön (vaakasuorat sanat kirjoitetaan vasemmalta oikealle ja pystysuorat sanat ylhäältä alas), ja niillä on yhteinen ensimmäinen ja viimeinen kirjain."
                },
                "bilingia": {
                    "en": "Anyway, your task in BILINGIA is to find two words, one in Finnish and one in Swedish. The words are arranged in a cross and have the middle letter in common.",
                    "sv": "Hursomhelst, din uppgift i BILINGIA är att hitta två ord, ett på finska och ett på svenska. Orden är lagda i ett kors och har mittenbokstaven gemensamt.",
                    "fi": "Joka tapauksessa, BILINGIAssa tehtäväsi on löytää kaksi sanaa, yksi suomeksi ja yksi ruotsiksi. Sanat on järjestetty ristiin, ja niillä on yhteinen keskimmäinen kirjain."
                },
                "anagramia": {
                    "en": "Anyway, in ANAGRAMIA, your task is to find three words. The three words have at least three letters in common. For example, the words \"anger\", \"angle\" and \"glare\" would have the letters a, e, and g in common.",
                    "sv": "Hursomhelst, din uppgift i BILINGIA är att hitta två ord. Orden formar en fyrkant (de horisontella orden är skrivna från vänster till höger, och de vertikala från toppen till botten), och de delar första och sista bokstäverna.",
                    "fi": "Joka tapauksessa, tehtäväsi BÄNDIVAUNUssa on löytää neljä sanaa. Sanat muodostavat neliön (vaakasuorat sanat kirjoitetaan vasemmalta oikealle ja pystysuorat sanat ylhäältä alas), ja niillä on yhteinen ensimmäinen ja viimeinen kirjain."
                }
            },
            "useEnter": {
                "en": "Enter a letter into the selected tile by using the keyboard. Press Return/Enter when ready to try a guess.",
                "sv": "Mata in en bokstav i den valda rutan med hjälp av tangentbordet. Tryck på enter/retur när du har matat in alla bokstäver.",
                "fi": "Kirjoita kirjain valittuun ruutuun näppäimistöllä. Paina enter tai return, kun olet syöttänyt kaikki kirjaimet."
            },
            "youHave": {
                "en": "You have",
                "sv": "Du har",
                "fi": "Sinulla on",
            },
            "attemptsToWin": {
                "en": "attempts to win the game.",
                "sv": "försök på dig att vinna.",
                "fi": "yritystä voittaaksesi.",
            },
            "selected": {"en":"Selected", "sv":"Vald", "fi": "Valittu"},
            "clue": {"en":"Clue", "sv":"Ledtråd", "fi":"Vihje"},
            "correct": {"en":"Correct", "sv":"Korrekt", "fi":"Oikea"},
            "existsInWord": {"en":"Exists in another place in the word", "sv":"Finns på annan plats i ordet", "fi":"Eri paikassa sanassa"},
            "existsInOtherWord": {"en":"Exists in another word", "sv":"Finns i ett annat ord", "fi":"Toisella sanalla"},
            "allUsed": {"en":"All are used / doesn't exist", "sv":"Alla använda / finns inte", "fi":"Kaikki käytetty / ei käytössä"},
            "empty": {"en":"Empty / changed by player","sv":"Tom / ändrad av spelaren", "fi":"Tyhjä / muokattu"},


            "settings": {
                "en":"Settings",
                "sv": "Inställningar",
                "fi": "Asetukset"

            },
            "uiLanguage": {
                "en": "Language",
                "sv": "Språk",
                "fi": "Kieli"
            },
            "darkMode": {
                "en": "Dark mode",
                "sv": "Mörkt läge",
                "fi": "Pimeä tila"
            },
            "navigate": {
                "en":"Navigate to next cell after input",
                "sv": "Gå till nästa ruta efter inmatning",
                "fi": "Siirry seuraavaan ruutuun"
            },
            "lookEmUp": {
                "en":"Unknown words? Well, look them up on wiktionary:",
                "sv":"Okända ord? Kolla upp dem på wiktionary:",
                "fi":"Tuntemattomia sanoja? Tarkista ne wiktionarystä:"
            },
            "moreThanWords": {
                "en":"More than",
                "sv":"Fler än",
                "fi":"Enemmän kuin"
            },
            "onlyCorrectWords": {
                "en":"words do not exist in the dictionary",
                "sv":"ord finns inte i ordlistan",
                "fi":"sanaa eivät ole sanalistassa"
            },
            "useOnlyCorrectWords": {
                "en":"At least some input must be in wordlist",
                "sv":"Åtminstone nåt inmatat finns i ordlistan",
                "fi":"Ainakin joitakin sanoja on sanalistassa"
            },
            "missingLetters": {
                "en":"Enter letters into all the cells",
                "sv":"Mata in bokstäver i alla rutor",
                "fi":"Kirjoita kirjaimet kaikkiin ruutuihin"
            },
            "swedishWordlist": {
                "en": "Swedish wordlist is based on",
                "sv": "Den svenska ordlistan är baserad på",
                "fi": "Ruotsinkielinen sanalista",
            },
            "finnishWordlist": {
                "en": "Finnish wordlist is based on",
                "sv": "Den finska ordlistan är baserad på",
                "fi": "Suomenkielinen sanalista",
            }

        }
        }
    },
    methods: {
        setLanguage: function(lang) {
            this.uiLanguage = lang;
        },
        l18n: function(lang, string, section=null) {
            if (section != null) {
                if (this.localise[section] != undefined && this.localise[section][string] != undefined) {
                    if  (this.localise[section][string][lang] != undefined) {
                        return this.localise[section][string][lang];
                    } 
                }
            } else {
                if (this.localise[string] != undefined) {
                    if  (this.localise[string][lang] != undefined) {
                        return this.localise[string][lang];
                    } 
                }
            }
            
            return "UNKNOWN >> " + lang + " >> " + string;
        }
    }
}