<template>
    <div v-on:click="$emit('selectCell')"
                v-bind:class="[
                                (isCellAvailable ? ('cell ' + cellStatus) :'dummy'),
                                (isCellSelected ? 'selected':''),
                                (wasJustInput?'wasJustInput':''),
                                ((requestedInput && !reportingCell)?'missingInput':''),
                                (celebrate?'celebrate-opacity celebrate celebrate-'+row:
                                (loser?'loser':''))
                ]">
                    <slot></slot>
                </div>
</template>

<script>
export default ({
     props: {
        isCellAvailable: {
            type: Boolean,
            default: true,
            },
        cellStatus: String,
        isCellSelected: Boolean,
        requestedInput: Boolean,
        wasJustInput: Boolean,
        row: Number,
        column: Number,
        reportingCell: Boolean,
        celebrate: Boolean,
        loser: Boolean
    },
})
</script>


<style scoped>
.cell {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size);
  line-height: var(--font-size);
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  border: 1px solid black;
  text-transform: uppercase;
  opacity: 1;
  transition: background-color 1s linear 0s, opacity 0.25s linear 1.75s;
  color: black;
}

.darkMode .cell  {
    border: 1px solid white;
    color: white;
}

@media only screen and ( max-height: 639px ) {
.cell {
  font-size: var(--small-font-size);
  line-height: var(--small-font-size);
}
}

.dummy {
      display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size);
    line-height: var(--font-size);
    font-weight: bold;
    border: 1px solid #fcfcff;
}

.eliminated {
  opacity: 0;
}

.darkMode .dummy {
    border: 1px solid #121212;
}

.given {
    background-color: #6699ff;
}

.darkMode .given {
    background-color: #21407e;
}

.correct {
    background-color: #7eec47;
}

.darkMode .correct {
    background-color: #2f5c19;
}

.exists {
    background-color: #cc55bb;
}

.darkMode .exists {
    background-color: #68225e;
}

.existsInWord {
    background-color: #e4d126;
}

.darkMode .existsInWord {
    background-color: #635a0a;
}

.doesntExist {
  background-color: #9b8f8f;  
}

.darkMode .doesntExist {
   background-color: #464444;  
}

.inputOrWrong {
    background-color:white;
}

.darkMode .inputOrWrong {
    background-color:#121212;
}

.normal {
    background-color:white;
}

.darkMode .normal  {
    background-color: #121212;
}

.selected {
    border: 5px solid black;
}

.darkMode .selected {
    border: 5px solid white;
}

@media only screen and ( max-height: 639px ) {
.selected {
    border: 4px solid black;
}

.darkMode .selected {
    border: 4px solid white;
}
}


/* cell animation */
.wasJustInput {
  animation-name: input-animation;
  animation-duration: 0.15s;
  animation-iteration-count: 1;
  animation-timing-function: linear; 
}

.missingInput {
  animation-name: missing-input-animation;
  animation-duration: 0.15s;
  animation-iteration-count: 3;
  animation-timing-function: linear; 
}

@keyframes input-animation {
  0% {
    width:100%;
    height:100%;
    margin-left:0%;
    margin-top:0%;
  }

  60% {
      width:92%;
      height:92%;
      margin-left:4%;
      margin-top:14%;
  }

  100% {
      width:100%;
      height:100%;
      margin-left:0%;
      margin-top:0%;
  }
}

@keyframes missing-input-animation {
  0% {
    width:100%;
    height:100%;
    margin-left:0%;
    margin-top:0%;
  }

  60% {
      width:92%;
      height:92%;
      margin-left:4%;
      margin-top:4%;
  }

  100% {
      width:100%;
      height:100%;
      margin-left:0%;
      margin-top:0%;
  }
}

.loser {
    color: white;
    background-color: black;
}

.darkMode .loser {
    color: black;
    background-color:white;
}

.celebrate-opacity {
  background-color: rgb(0, 0, 121);
  color:white;
}

.darkMode .celebrate-opacity {
    background-color: rgb(0, 0, 121);
    color:white;
}

.celebrate {
  animation-name: rainbow-animation;
  animation-duration: 4.25s;
  animation-iteration-count: 10;
  animation-timing-function: linear; 
  background-color: rgb(0, 0, 121);
  color:white;
}

.celebrate-1 {
  animation-delay: 3.75s;
}

.celebrate-2 {
  animation-delay: 3.25s;
}

.celebrate-3 {
  animation-delay: 2.75s;
}

.celebrate-4 {
  animation-delay: 2.25s;
}

.celebrate-5 {
  animation-delay: 1.75s;
}

.celebrate-6 {
  animation-delay: 1.25s;
}

.celebrate-7 {
  animation-delay: 0.75s;
}

.celebrate-8 {
  animation-delay: 0.25s;
}

@keyframes rainbow-animation {
    0% {
        background-color: rgb(0, 0, 121);
        color: white;
    }

    50% {
        background-color: rgb(149, 0, 255);
        color: white;
    }

    100% {
        background-color: rgb(0, 0, 121);
        color: white;
    }
}
</style>