<template>
    <div class="windowBox" v-bind:id="id">
        <div class="windowBar">
        <button v-on:click="$emit('close')" aria-label="Close Window"><font-awesome-icon size="lg" icon="fa-solid fa-times" /></button>
        </div>
        <h2 v-html="title"></h2>
        <slot></slot>
    </div>
</template>

<script>

export default {
    props: {
        title: String,
        id: String,
        visible: Boolean
    },
    data: function() { return { 
        }
    },
    mounted: function() {
    },
    created: function () {

    
    },
    destroyed: function () {
    },
    methods: {}
}
</script>


<style scoped>
.windowBar {
    text-align: right;
    position: absolute;
    right: 10px;
}

.windowBox {
    position: absolute;
    width: 100%;
    max-width: var(--max-width);
    padding: 10px;
    top: 50%;
    height: 50%;
    pointer-events: none;
    background: #fcfcff;
    text-align: left;
    opacity: 0;
    transition: height 0.5s, top 0.5s, opacity 0.5s;
    overflow: hidden;
    box-sizing: border-box; 
}

.windowBox h2 {
    margin-top: 0;
}

.darkMode .windowBox {
    background-color: #121212;
}

.windowBar button {
    height: 30px;
    width: 30px;
    cursor: pointer;
}


</style>