import axios from "axios";

export var wordStore = {
    datasets: {
        'en': {
            '4': "/assets/datasets/en_words4.txt",
            '5': "/assets/datasets/en_words5.txt",
            '6': "/assets/datasets/en_words6.txt",
            '7': "/assets/datasets/en_words7.txt",
            '8': "/assets/datasets/en_words8.txt",
        },
        'sv': {
            '4': "/assets/datasets/sv_words4.txt",
            '5': "/assets/datasets/sv_words5.txt",
            '6': "/assets/datasets/sv_words6.txt",
            '7': "/assets/datasets/sv_words7.txt",
            '8': "/assets/datasets/sv_words8.txt",
        },
        'fi': {
            '4': "/assets/datasets/fi_words4.txt",
            '5': "/assets/datasets/fi_words5.txt",
            '6': "/assets/datasets/fi_words6.txt",
            '7': "/assets/datasets/fi_words7.txt",
        }
    },

    store: {},

    languages: [],

    isLoading: 1,
    callbacks: [],

    registerCallback: function(callback) {
        this.callbacks.push(callback);
        if (this.isLoading == 0) callback(); // if already loaded, call back immediately
    },

    loadData: async function(lings, lengths) { // todo, add setting for word lengths
        this.isLoading = 1;


        for (let ling of lings) {
            for (let len of lengths) {
                let lenstr = ""+len;
                if (!(ling in this.store)) this.store[ling] = {}; // create the language key if not present
                if (lenstr in this.store[ling] ) continue; // this list has already been loaded, leave it be
                
                let response = await axios.get(this.datasets[ling][lenstr]);
                this.store[ling][lenstr] = response.data.split("\r\n");
                
            }
        }

        for (let callback of this.callbacks) {
                callback();
        }

        this.isLoading = 0;

        return this.store;
    },
 
    getWord: function(ling, length, usedLetters, previousWords, anagramLetters=null) {
        if (usedLetters.length != length) return 'ERROR'; // must match 

        let words = this.store[ling][''+length];
   
        let wordsFiltered = words;
        for (let i = 0; i < length; i++) {
            if (usedLetters[i]!='') {
                wordsFiltered = wordsFiltered.filter((word) => word[i] == usedLetters[i]);
            }
        }

        if (anagramLetters && anagramLetters.length>0) {
            for (let i = 0; i < anagramLetters.length; i++) {
                wordsFiltered = wordsFiltered.filter((word) => word.indexOf(anagramLetters[i])>=0);
            }
        }

        if (previousWords.length>0) {
            for (let i = 0; i < previousWords.length; i++) {
                wordsFiltered = wordsFiltered.filter((word) => word!==previousWords[i]);
            }
        }

        if (wordsFiltered.length == 0) return ''; // empty list

        let found = false;
        let tries = 0;
        let randy = -1;
        while(!found) { // duplicate checks
            found = true;
            randy = Math.floor(Math.random() * wordsFiltered.length);
            for (let word of previousWords) {
                if (word == wordsFiltered[randy]) { // duplicate
                    found = false;
                    break;
                }
            }
            tries+=1;
            if (tries >= 5) return ''; // could not find a unique word, end
        }
        if (randy >= 0)
            return wordsFiltered[randy];
        else return '';


    },

    isWordInList: function(ling, inword) {
        for (let ldx in this.store[ling]) {
            
            for (let word of this.store[ling][ldx]) {
                if (inword==word) return true;
            }
        }

        return false;
    },
};