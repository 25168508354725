<template>
    <header>
        <h1>{{ title.toUpperCase() }} <span v-if="subheading">{{ subheading }}</span></h1>
                <div>
                    <button v-if="newGame" v-on:click="$emit('createNewGame')" aria-label="Start new game"><font-awesome-icon size="lg" icon="fa-solid fa-play-circle" /></button>
                    <button class="menuButton" v-on:click="$emit('click','helpBox')" aria-label="Help"><font-awesome-icon size="lg" icon="fa-solid fa-info-circle" /></button>
                    <button class="menuButton" v-on:click="$emit('click','statsBox')" aria-label="Statistics"><font-awesome-icon size="lg" icon="fa-solid fa-chart-bar" /></button>
                    <button class="menuButton" v-on:click="$emit('click','settingsBox')" aria-label="Settings"><font-awesome-icon size="lg" icon="fa-solid fa-cog" /></button>
                </div>
    </header>
</template>

<script>
export default {
    props: {
        title: String,
        subheading: String,
        id: String,
        visible: Boolean,
        newGame: Boolean
    },
    data: function() { return { 
        }
    },
    mounted: function() {
    },
    created: function () {

    
    },
    destroyed: function () {
    },
    methods: {}
}
</script>

<style scoped>
header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width);
    height: 50px;
    padding: 5px;
    box-sizing: border-box; 
    border-bottom: 1px solid var(--border-color);
}

h1 {
 font-size: 2rem;   
 user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

@media only screen and (min-width: 1024px) {
    h1 {
        font-size: 2rem;
    }
}

@media only screen and ( max-height: 639px ) {
    h1 {
        font-size: 1.5rem;
    }
}


@media only screen and ( max-width: 800px ) {
    h1 {
        font-size: 1.5rem;
    }
}

.buttonContainer {
    width: 100%;
    text-align: center;
}

button {
    background-color: #fafaff;
    border: 0;
    color: black; /* White text */
    cursor: pointer;
}

button:hover {
    background-color: #fafaff;
}

.darkMode button {
    background-color: #121212;
    color:white;
}

.darkMode button:hover {
    background-color:#121212;
}
</style>