<template>
    <div id="alertBox">
        <font-awesome-icon v-if="type == 'error'" size="lg" icon="fas fa-exclamation-circle" />&nbsp;<span style="padding-left: 10px" v-html="text"></span>
    </div>
</template>


<script>
export default {
    props: {
        title: String,
        id: String,
        type: String,
        text: String,
        enabled: Boolean,
        timeout: Number
    },
    watch: {
        enabled: function(val) {
            if (val) {
                document.getElementById('alertBox').style.visibility = 'visible';
                document.getElementById('alertBox').style.opacity = 1;
                
            } else {
                 document.getElementById('alertBox').style.opacity = 0;
                 setTimeout(function() {document.getElementById('alertBox').style.visibility = 'hidden'; }, 0.35*1000);
            }
        }
    },
    data: function() { return { 
        }
    },
    mounted: function() {
    },
    created: function () {},
    destroyed: function () {
    },
    methods: {}
}
</script>

<style scoped>
#alertBox {
    z-index: 3;
    position: absolute;
    padding: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    top: 33%;
    left: 50%;
    margin-top: -25px;
    margin-left: -165px;
    width: 330px;
    height: 100px;
    opacity: 0;
    visibility: hidden;
    border: 5px solid black;
    background: #fcfcff;
    color: black;
    box-sizing: border-box;
    transition: opacity 0.35s;
}

.darkMode #alertBox {
    border: 5px solid white;
    background: #121212;
    color:white;
}

@media only screen and ( max-height: 639px ) {
    #alertBox {
        margin-top: -25px;
        margin-left: -150px;
        width: 300px;
        height: 100px;
    }
}

@media only screen and (min-width: 800px) and ( min-height: 640px ) and ( max-height: 767px ) {
    #alertBox {
        margin-top: -25px;
        margin-left: -165px;
        width: 330px;
        height: 75px;
    }
}

@media only screen and (min-width: 800px) and ( min-height: 768px ) {
    #alertBox {
        margin-top: -25px;
        margin-left: -250px;
        width: 500px;
        height: 75px;
    }
}
</style>