<template>
<div class="root">
    <menubar @click="showWindow" @createNewGame="createNewGame" :title="localise[gameConfig.name][uiLanguage]" :subheading="gameConfig.variant" :newGame="state.hasWon|| state.hasLost" />
    <div class="gameContainer">
        <div class="gameboard" v-bind:class="['rows-g',boardAspectRatio>1?'gameboard-width-rules':'gameboard-height-rules']">
            <div v-for="row in gameConfig.height" :key="row" class="row columns-g" v-bind:id="'row-'+row">
                <cell v-for="column in gameConfig.width" :key="column" @selectCell="selectCell(row,column)" 
                    :row="row" :column="column" :reportingCell="gameConfig.reportingCell.r==row && gameConfig.reportingCell.c==column"
                 :isCellAvailable="isCellAvailable(row,column)" :cellStatus="checkCell(row,column)"
                 :isCellSelected="isCellSelected(row,column)&&state.hasLoaded&&(!state.hasWon&&!state.hasLost)"
                 :wasJustInput="(state.wasJustInput.r==row && state.wasJustInput.c==column)"
                 :requestedInput="(state.requestedInput && getInputAtCell(row,column)==''&&checkCell(row,column)!='given')"
                 :celebrate="(state.hasWon && isCellAvailable(row,column))"
                 :loser="state.hasLost && isCellAvailable(row,column)">
                    <span v-if="state.isPlaying && (checkCell(row,column)!='inputOrWrong' && checkCell(row,column)!='input' && checkCell(row,column)!='exists' && checkCell(row,column)!='existsInWord' && checkCell(row,column)!='doesntExist') ">
                        <span v-if="gameConfig.columns.includes(column)">
                        {{ getCorrectSolutionAtCell(row,column) }}
                        </span>
                        <span v-else-if="gameConfig.rows.includes(row)">
                        {{ getCorrectSolutionAtCell(row,column) }}
                        </span>
                    </span>
                    <span v-else-if="state.isPlaying && (checkCell(row,column)=='input' || checkCell(row,column)=='exists' ||  checkCell(row,column)=='existsInWord' || checkCell(row,column)=='doesntExist')">
                        {{ getInputAtCell(row,column) }}
                    </span>

                    <span v-else-if="(state.hasLost || state.hasWon) && !state.isPlaying">
                        {{ getCorrectSolutionAtCell(row,column) }}
                    </span>
                    <span class="roundsSpan" v-if="state.hasLoaded && !state.hasWon && !state.hasLost && gameConfig.reportingCell.r==row && gameConfig.reportingCell.c==column">
                        {{gameConfig.maxTries - currentGame.tries}}
                    </span>

                </cell>
            </div>
        </div>
     
    </div>
    <keyboard @keystroke="onKeyboardInput" :keyboardLayout="gameConfig.keyboardLayout" 
    :showNew = "(state.hasWon||state.hasLost)" :enabled="true" :flash="state.solutionCheckable" 
    :greenLetters="currentGame.allInstancesFoundLetter" :greyLetters="currentGame.doesntExistLetter" />

    <AlertBox :timeout="generalConfig.timeout" :enabled="state.threwAlert" :title = "state.alert.title" :text = "state.alert.text" :type="state.alert.type" />

    <WindowBox id="settingsBox" :title="localise.settings[uiLanguage]" @close="closeWindow('settingsBox')">
        <div class="settingsRow" v-if="gameConfig.languages.length>1">
            {{ localise.uiLanguage[uiLanguage] }}
            <span>
                <button :class="['toggleButton', (uiLanguage == language)?'toggleSelected':'']" v-on:click="uiLanguage = language" v-for="language in gameConfig.languages" :key="language">
                    {{ language }}
                </button>
            </span>
        </div>
        <div class="settingsRow">
        {{ localise.darkMode[uiLanguage] }}
            <label class="switch">
                <input type="checkbox" v-model="generalConfig.useDarkMode" v-on:change="toggleDarkMode(); saveGeneralConfig()">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="settingsRow">
        {{ localise.navigate[uiLanguage] }}
            <label class="switch">
                <input type="checkbox" v-model="generalConfig.navigateNext" v-on:change="saveGeneralConfig()">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="settingsRow">
        {{ localise.useOnlyCorrectWords[uiLanguage] }}
            <label class="switch">
                <input type="checkbox" v-model="generalConfig.onlyCorrectWords" v-on:change="saveGeneralConfig()">
                <span class="slider round"></span>
            </label>
        </div>
        <div style="font-size: 14px; text-align:right;">
            Version {{this.version}} ({{this.engineVersion}})<br />
            BANDWAGON © 2022 <a href="https://stroberock.com/" target="_new">Stroberock</a> (michael@stroberock.com). All rights reserved.<br />
            <a href="https://stroberock.com/privacy-policy/" target="_new">Privacy Policy.</a>
        </div>
        <div style="font-size: 14px; text-align:right;" v-if="gameConfig.languages.includes('sv')">
            <br /><br /> 
            <b>{{ localise.swedishWordlist[uiLanguage] }}:</b> <a href="https://spraakbanken.gu.se/resurser/saldo" target="_new">SALDO 2.3</a>, © 2015 Lars Borin, Markus Forsberg, Lennart Lönngren and Språkbanken (the Swedish Language Bank), <a href="http://spraakbanken.gu.se/" target="_new">University of Gothenburg, Sweden</a>. Used under <a href="http://creativecommons.org/licenses/by/3.0/" target="_new">Creative Commons Attribution (BY) 3.0</a>. 
        </div> 
        <div style="font-size: 14px; text-align:right;" v-if="gameConfig.languages.includes('fi')">
            <br /><br /> 
            <b>{{ localise.finnishWordlist[uiLanguage] }}:</b> <a href="https://kaino.kotus.fi/sanat/nykysuomi/" target="_new">Kotimaisten kielten tutkimuskeskuksen nykysuomen sanalista, versio 1</a>. © Kotimaisten kielten tutkimuskeskus 2006.  Julkaistu 15.12.2006. Licensed under <a href="https://joinup.ec.europa.eu/collection/eupl/eupl-text-eupl-12" target="_new">EUPL-1.2-or-later</a>.
        </div>
    </WindowBox>
    <WindowBox id="statsBox" :title="(state.hasWon ? localise.hasWon[uiLanguage] : (state.hasLost ? localise.lament[uiLanguage] + '<br />'+localise.hasLost[uiLanguage]: localise.statistics[uiLanguage]))" @close="closeWindow('statsBox')">
        <div class="row columns-3">
            <div style="flex: 1.5"></div>
            <VictoryStats :width="140" :height="140" v-if="userStats.wins+userStats.losses>2"
                    :chartData="{ 'labels': [localise.wins[uiLanguage], localise.losses[uiLanguage]],
                    'datasets': [{'data': [userStats.wins, userStats.losses], 'backgroundColor': ['#2f5c19','#21407e']}] }" 
                    :options="{'responsive': false, 'legend':{'display': false}, 'elements': { 'arc': { 'borderWidth': 0.5 }
                    }}" />
            <div style="flex: 1.5"></div>
        </div>
        <br />
        <div class="statsRow rows-4">
            <div class="row columns-3">
                <div class="smallCell">{{localise.gamesPlayed[uiLanguage]}}</div>
                <div class="smallCell">{{localise.wins[uiLanguage]}}</div>
                <div class="smallCell">{{localise.losses[uiLanguage]}}</div>
            </div>
            <div class="row columns-3">
                <div class="smallCell statistic">{{userStats.wins+userStats.losses}}</div>
                <div class="smallCell statistic">{{userStats.wins}} <span v-if="userStats.wins+userStats.losses>0"> </span></div>
                <div class="smallCell statistic">{{userStats.losses}}  <span v-if="userStats.wins+userStats.losses>0"> </span></div>
            </div>
            <div class="row columns-3">
                <div class="smallCell">{{localise.fastestSolution[uiLanguage]}}</div>
                <div class="smallCell">{{localise.currentStreak[uiLanguage]}}</div>
                <div class="smallCell">{{localise.bestStreak[uiLanguage]}}</div>
            </div>
             <div class="row columns-3">
                <div class="smallCell statistic" v-if="userStats.fastestSolution<=gameConfig.maxTries">{{userStats.fastestSolution}}</div>
                <div class="smallCell statistic" v-else>-</div>
                <div class="smallCell statistic">{{userStats.streak}}</div>
                <div class="smallCell statistic">{{userStats.maxStreak}}</div>
            </div>
        </div>
        <div v-if="state.hasWon||state.hasLost">
            {{ localise.lookEmUp[uiLanguage] }}<br />
            <span style="margin-right: 10px" v-for="word, index in currentGame.correctColumnWords" :key="word"><a :href="'https://'+currentGame.correctColumnLanguages[index]+'.wiktionary.org/wiki/'+word+'#'+localise.codeMap[currentGame.correctColumnLanguages[index]]" target="_new">{{ word }} [{{currentGame.correctColumnLanguages[index]}}]</a></span>
            <span style="margin-right: 10px" v-for="word, index in currentGame.correctRowWords" :key="word"><a :href="'https://'+currentGame.correctRowLanguages[index]+'.wiktionary.org/wiki/'+word+'#'+localise.codeMap[currentGame.correctRowLanguages[index]]" target="_new">{{ word }} [{{currentGame.correctRowLanguages[index]}}]</a></span>
            
        </div>
        <br /><br />
        <div class="buttonContainer" v-if="state.hasWon||state.hasLost"><button id="newGameButton" v-on:keyup.enter = "createNewGame(); closeWindow('statsBox');" class="button" v-on:click="createNewGame(); closeWindow('statsBox');" ><font-awesome-icon icon="fa-solid fa-play-circle" /> {{localise.letsPlayAgain[uiLanguage]}}</button></div>
        <div class="buttonContainer" v-else><span class="button" v-on:click="createNewGame(); closeWindow('statsBox')"><font-awesome-icon icon="fa-solid fa-play-circle" /> {{localise.startNewGame[uiLanguage]}}</span></div>
    </WindowBox>

    <WindowBox id="helpBox" :title="localise.welcomeTo[gameConfig.name][uiLanguage]" @close="closeWindow('helpBox')">
        {{ localise.intro[gameConfig.name][uiLanguage] }}<br /><br />
            {{ localise.rules[gameConfig.name][uiLanguage] }}  
        <br /><br />
        <div class="helpRow rows-2">
            <div class="row columns-6">
                <cell :cellStatuts="'inputOrWrong'" :isCellSelected="true">s</cell>
                <cell :cellStatus="'given'">g</cell>
                <cell :cellStatus="'correct'">c</cell>
                <cell :cellStatus="'existsInWord'">w</cell>
                <cell :cellStatus="'exists'">e</cell>
                <cell :cellStatus="'doesntExist'">d</cell>
            </div>
             <div class="row columns-6">
                <div class="helpCell">{{ localise.selected[uiLanguage] }}</div>
                <div class="helpCell">{{ localise.clue[uiLanguage] }}</div>
                <div class="helpCell">{{ localise.correct[uiLanguage] }}</div>
                <div class="helpCell">{{ localise.existsInWord[uiLanguage] }}</div>
                <div class="helpCell">{{ localise.existsInOtherWord[uiLanguage] }}</div>
                <div class="helpCell">{{ localise.allUsed[uiLanguage] }}</div>

            </div>
        </div>
        <br />
        {{localise.useEnter[uiLanguage]}} {{ localise.youHave[uiLanguage] }} {{gameConfig.maxTries}} {{localise.attemptsToWin[uiLanguage]}}
        <br /><br /><br />
        <div class="buttonContainer"><span class="button" v-on:click="closeWindow('helpBox')"><font-awesome-icon size="lg" icon="fa-solid fa-play-circle" /> {{localise.thanksForTheInfo[uiLanguage]}}</span></div>
    </WindowBox>

  </div>
</template>

<script>

//



import GameEngine from "./GameEngine.js";
//import VictoryStats from "./VictoryStats.vue"
import keyboard from "./keyboard.vue";
import menubar from "./menubar.vue";
import WindowBox from "./WindowBox.vue";
import AlertBox from "./AlertBox.vue";
import cell from "./cell.vue";

export default {
  mixins: [GameEngine],
  name: 'Bandwagon',
    
  components: {
      keyboard,
      menubar,
      WindowBox,
      AlertBox,
      cell,
      VictoryStats: () => import('./VictoryStats.vue') // lazy load
  },
  props: {
      gameConfigurationProp: String,
      uiLanguageProp:String,

  },
  data: () => { return {
      version: "0.0.16",
  }},
  mounted: function() {

  }, // hook functions are merged, so both this and the one in GameEngine is called
  created: function() {
  }, // see hook functions above
   methods: { // overrides where necessary
         // selectWords if needed 
    }
}
</script>

<style>

html {
    height: 100%;
}

:root {
    --rows: 1;
    --columns: 1;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    background-color: #fcfcff;
    color: #121212;
    
}

body.darkMode {
    background-color: #121212;
    color: white;
}


h2 {
    padding-top: 10px;
    font-size: 1.5rem;
    text-align: left;
}

.root {
    width: 100%;
    max-width: 480px;
    margin:  auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 16px;
    --font-size: 2rem;
    --small-font-size: 1.7rem;
    --key-size: 1rem;
    --standard-gap: 5px;
    --max-width: 480px;
    --border-color: gray;
}

header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max-width);
    height: 50px;
    padding: 5px;
    box-sizing: border-box; 
    border-bottom: 1px solid var(--border-color);
}


.helpRow {
    display: grid;
    max-width: 100%;
    min-height: 100px;
    max-height: 200px;

    grid-gap: 10px;
    padding: 10px;
    box-sizing: border-box;
}

.statsRow {
    display: grid;
    max-width: var(--max-width);

    grid-gap: 10px;
    padding: 10px;
    padding-bottom:10px;
    box-sizing: border-box;
}

.gameboard {
    aspect-ratio: var(--columns) / var(--rows);
    display: grid;
    max-width: 450px;
    width: 450px; /* 332px*/
    height: 350px;
    grid-gap: 5px;
    padding: 5px;
    box-sizing: border-box;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}



.row {
    display: grid;
    grid-gap: 5px;
}

@media only screen and ( max-height: 639px ) {
.row {
    grid-gap: 5px;
}
}

@media only screen and ( max-height: 639px ) {
.gameboard {
    max-width: 450px;
    width: 330px;
    height: 280px;
    grid-gap: 5px;
    padding: 5px;
}

#helpBox {
    font-size: 14px;
}
}



@media only screen and (min-width: 800px) and ( min-height: 640px ) and ( max-height: 767px ) {
.gameboard {
    width: 450px;
    height: 390px;
}


}

@media only screen and (min-width: 500px) and ( min-height: 768px ) {
.gameboard {
    width: 450px;
    height: 500px;
    row-gap: 10px;
}

.row {
    grid-gap: 10px;
}

}

/* these two overrides the gameboard size, depending on the aspect ratio */
.gameboard-height-rules {
    width: auto;
}

.gameboard-width-rules {
    height: auto;
}

.gameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2;
    overflow: hidden;
}

/* grids */
/* --row and --columns need to be set programmatically when the game is loaded */
.columns-g {
    grid-template-columns: repeat(var(--columns), 1fr); 
}

.rows-g {
    grid-template-rows: repeat(var(--rows), 1fr);
}

.columns-3 {
    grid-template-columns: repeat(3, 1fr); 
}

.columns-6 {
    grid-template-columns: repeat(6, 1fr); 
}

.rows-2 {
    grid-template-rows: repeat(2, 1fr);
}

.rows-4 {
    grid-template-rows: repeat(4, 1fr);
}

.smallCell {
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    border: 0;
}

.statistic {
    font-size: 24px;
    line-height: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.helpCell {
    font-size: 12px;
    line-height: 12px;
    text-align: center;
}

/* dialogs and overlays */

.versionBox {
    position: fixed;
    right: 10px;
    bottom: 10px;
    color: #666;
}

/* buttons */ 
.buttonContainer {
    width: 100%;
    text-align: center;
}

button {
    background-color: #fcfcff;
    border: 0;
    color: black; /* White text */
    cursor: pointer;
    font-size: 16px;
}

button:hover {
    background-color: #fcfcff;
}

.darkMode button {
    background-color: #121212;
    color:white;
}

.darkMode button:hover {
    background-color:#121212;
}




button.toggleButton {
    cursor: pointer;
    padding: 4px;
}

.toggleSelected {
    border: 3px solid black;
}

.darkMode .toggleSelected {
    border: 3px solid white;
}

button.menuButton  {
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.button {
    padding: 10px 24px; /* Some padding */
    background-color: #121212; 
    color: white; /* White text */
    cursor: pointer; /* Pointer/hand icon */
}

.button:hover {
  background-color: #121212;
}

.darkMode .button {
    background-color: white;
    color: black;
}

.darkMode .button:hover {
    background-color:white;
}


.settingsRow {
    display: flex;
    justify-content: space-between;
    line-height: 25px;
    text-align: right;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--border-color);
}


/* checkboxes */
.switch {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(29px);
  -ms-transform: translateX(29px);
  transform: translateX(29px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 36px;
}

.slider.round:before {
  border-radius: 50%;
}


a {
    color: black;
    text-decoration: underline;
}

a:hover {
    color: black;
}

a:visited {
    color: black;
}

a:active {
    color: black;
}

.darkMode a {
    color: White;
}

.darkMode a:hover {
    color: White;
}

.darkMode a:visited {
    color: White;
}

.darkMode a:active {
    color: White;
}


</style>
