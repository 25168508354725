
// all the generalisable bits of the game goes here
import {wordStore} from "./wordStore.js";
import GameConfigurations from "./GameConfigurations.js";
import L18N from "./L18N.js";


export default {
    mixins: [GameConfigurations,L18N],
    data: function() { return { 
        engineVersion: "bwe_0.8",
        debug: false,
        cheatMode: false,
        boardAspectRatio: 1,
        gameConfigurationName: 'bandwagon-5-6-en', // default
        gameConfig: {}, // populated on creation
        userStats: {
            streak: 0,
            maxStreak: 0,
            fastestSolution: 100,
            wins: 0,
            losses: 0,
            firstTime: true,
        }, // streak, win distributions
        additionalUserStats: {
            startedGames: 0,
            solves: {'1':0,'2':0}
        },
        state: {
            hasLoaded: false,
            isPlaying: false,
            isInputing: false, // set to true when starting to type 
            hasWon: false,
            hasLost: false,
            isPaused: false,
            blockNewGame: false,
            solutionCheckable: false,
            requestedInput: false, // used to indicated when player is notified of missing input; this is immediately reset to false once put to true, so it plays the input anim and then does not repeat until next time the user presses enter
            threwAlert: false, // used to throw an alert
            alert: {'title':'','text':'','type':''},
            writingLocation: {r:1,c:1},
            previousLocation: {r:-1,c:-1},
            wasJustInput: {r:-1,c:-1}
        },

        generalConfig: {
            useDarkMode: false,
            useHighContrastMode: false,
            navigateNext: true,
            onlyCorrectWords: false,
            timeout: 2,
            lossTimeout: 3,
            winTimeout: 4,
            inputTimeOut: 0.2, // for the input animation; should be a smidgeon longer than wasJustInput animation in css below
            missingOutputTimeout: 0.5, // for the missing input animation
            cellAssumption: 6 // the max number of cell rows
        },
        
        currentGame: {
            correctColumnWords: [], //in the order of the columns
            correctRowWords: [], //in the order of the rows
            correctColumnLanguages: [], //in the order of the columns
            correctRowLanguages: [], //in the order of the rows
            correctCells: [], // format: {r:1, c:1}
            givenCells: [],
            existsCells: [],
            existsInWordCells: [],
            availableCells: [],
            currentUserInput: [], // needs to be cleaned for correctCells
            correctSolution: [],
            doesntExistCells: [],
            doesntExistLetter: '', // always keep in memory the letters that don't exist at all // note, SET or Array apparently too complicated a data structure for reactivity to work, therefore using a string
            allInstancesFoundLetter: '', // all the letter for which all green ones have been found
            existInWordLetter: [],
            existLetter: [],
            tries: 0
        },
        
        
        }
    },
    // open graph
    metaInfo: function() {
        return {
            title: this.localise[this.gameConfig.name][this.uiLanguage] + " - " + this.localise.tagline[this.uiLanguage],
            meta: [
                {
                    property: 'og:title',
                    content: this.localise[this.gameConfig.name][this.uiLanguage] + " - " + this.localise.tagline[this.uiLanguage],
                },
                {
                    property: 'og:image',
                    content: 'https://bandwagon.stroberock.com/'+this.gameConfig.name+'-1200x630.png',
                },
                {
                    property: 'og:description',
                    content: this.localise.shortIntro[this.gameConfig.name][this.uiLanguage],
                },
                {
                    property: 'og:url',
                    content: 'https://bandwagon.stroberock.com'+window.location.pathname,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
            ]
        }
    },
    mounted: function() {
        this.loadUserStats();
        this.isMounted = true;
        
        
    },
    created: function () {
        // if properties
        if (this.gameConfigurationProp!=null) this.gameConfigurationName = this.gameConfigurationProp;
        if (this.uiLanguageProp!=null) this.uiLanguage = this.uiLanguageProp;

        this.loadGeneralConfig();

        this.logDebug("the configuration: " + this.gameConfigurationName);
        this.gameConfig = this.gameConfigs[this.gameConfigurationName];
        this.logDebug(this.gameConfig);

        // register the function to be called when data is available;
        this.logDebug("callback registered");
        wordStore.registerCallback(this.onDataLoaded);
        let lengths = new Set(); // using a set to avoid duplicate if width == height
        if (this.gameConfig.rows.length>0) lengths.add(this.gameConfig.width); // only add if there are row words
        if (this.gameConfig.columns.length>0) lengths.add(this.gameConfig.height); // only add if there are column words

        //important for the aspect ratio
        document.documentElement.style.setProperty('--rows', this.gameConfig.height); // widen it
        document.documentElement.style.setProperty('--columns', this.gameConfig.width); // widen it

        // aspect ratio: if width is larger than height, then width is the determiner of the max size of the board
        // (the css forces an aspect ratio too on the game board)
        this.boardAspectRatio = this.gameConfig.width/this.gameConfig.height;
        

        this.logDebug("loading data");
        wordStore.loadData(this.gameConfig.languages,lengths);
    },
    destroyed: function () {
        
        document.removeEventListener("keydown", window); // to avoid memory leaks
    },
    methods: {
        logDebug: function(streng) {
            if (this.debug) console.log(streng);
        },
        doCheat: function() {
            console.log("[CHEAT MODE] the words are: ");
            for (let c in this.currentGame.correctColumnWords) {
                console.log(this.currentGame.correctColumnWords[c] + " [" + this.currentGame.correctColumnLanguages[c]+"]");
            }
            for (let r in this.currentGame.correctRowWords) {
                console.log(this.currentGame.correctRowWords[r] + " [" + this.currentGame.correctRowLanguages[r]+"]");
            }

        },
        /* Loading and setting methods */
        loadGeneralConfig: function() { // these can be globalI suppose
            // game config
             if (localStorage.genConfigDarkMode) {
                 this.generalConfig.useDarkMode = (localStorage.genConfigDarkMode =="false"?false:true);
                 this.toggleDarkMode();
             }
             
             if (localStorage.genConfigNavigateNext) {
                 
                 this.generalConfig.navigateNext = (localStorage.genConfigNavigateNext =="false"?false:true);
                 
             }

             if (localStorage.genConfigHighContrastMode) {
                 
                this.generalConfig.useHighContrastMode = (localStorage.genConfigHighContrastMode =="false"?false:true);
                
            }

            if (localStorage.genConfigOnlyCorrectWords) {
                 
                this.generalConfig.onlyCorrectWords = (localStorage.genConfigOnlyCorrectWords =="false"?false:true);
                
            }
        },
        saveGeneralConfig: function() {
             localStorage.genConfigDarkMode = (this.generalConfig.useDarkMode?'true':'false');
             localStorage.genConfigHighContrastMode = (this.generalConfig.useHighContrastMode?'true':'false');
             localStorage.genConfigNavigateNext = (this.generalConfig.navigateNext?'true':'false');
             localStorage.genConfigOnlyCorrectWords = (this.generalConfig.onlyCorrectWords?'true':'false');
        },
        loadUserStats: function() {
            if (localStorage.getItem('stats-'+this.gameConfigurationName)) {
                try {
                    this.userStats = JSON.parse(localStorage.getItem('stats-'+this.gameConfigurationName));
                } catch(e) {
                this.logDebug("Error in stats for " + this.gameConfigurationName + ", removing");
                  localStorage.removeItem('stats-'+this.gameConfigurationName);
                }
            } 
            this.logDebug(localStorage.getItem('additionalStats-'+this.gameConfigurationName));
            if (localStorage.getItem('additionalStats-'+this.gameConfigurationName)) {
                try {
                    this.additionalUserStats = JSON.parse(localStorage.getItem('additionalStats-'+this.gameConfigurationName));
                } catch(e) {
                    
                this.logDebug("Error in additional stats for " + this.gameConfigurationName + ", removing");
                  localStorage.removeItem('additionalStats-'+this.gameConfigurationName);
                }
            }

        },
        saveUserStats: function() {
            this.logDebug("saved stats called");
            this.userStats.firstTime = false; // by definition
            const parsed = JSON.stringify(this.userStats);
            localStorage.setItem('stats-'+this.gameConfigurationName, parsed);
            this.logDebug("stats saved for " + 'stats-'+this.gameConfigurationName);
        },
        saveAdditionalUserStats: function() { // decomposed into a second function since we want to save the "started games" on create new game and 
            // no point in saving other stats
            const parsedAdd = JSON.stringify(this.additionalUserStats);
            localStorage.setItem('additionalStats-'+this.gameConfigurationName, parsedAdd);
            this.logDebug("additional stats saved for " + 'stats-'+this.gameConfigurationName);
        },
        saveCurrentGame: function() {
            const parsed = JSON.stringify(this.currentGame);
            localStorage.setItem('currentGame-'+this.gameConfigurationName, parsed);
            this.logDebug("saved current game for " + 'currentGame-'+this.gameConfigurationName);
        },
        deleteCurrentGame: function() { // delete a saved game upon completion/loss
            localStorage.removeItem('currentGame-'+this.gameConfigurationName);
        },
        loadCurrentGame: function() {
            if (localStorage.getItem('currentGame-'+this.gameConfigurationName)) {
                try {
                    this.currentGame = JSON.parse(localStorage.getItem('currentGame-'+this.gameConfigurationName));


                    return true;
                } catch(e) {
                this.logDebug("Error in current Game for " + this.gameConfigurationName + ", removing");
                  localStorage.removeItem('currentGame-'+this.gameConfigurationName);
                }
            }
            return false;
        },
        resetStats: function() {
            this.logDebug("reset stats called");
            this.userStats.streak = 0; 
            this.userStats.maxStreak = 0;
            this.userStats.wins = 0; 
            this.userStats.losses = 0;
            this.userStats.fastestSolution = 1000;
            this.userStats.firstTime = true;
            

            this.logDebug("done");
        },
        onDataLoaded: function() {
            this.logDebug("data loaded");
            if (this.userStats.firstTime) {
                this.logDebug("registered as first time, will do cleanup and show help");
                //this.resetStats(); // just to clear out any debug clutter
                this.showWindow('helpBox');
                this.userStats.firstTime = false;
            }
            if (!this.loadCurrentGame()) {
                this.logDebug("No current game present, creating new one");
                this.createNewGame();
            } else {
                this.state.isPlaying = true; // success, load new
                    // careful to avoid passing by reference; full copy
                this.state.writingLocation.r = this.gameConfig.writingSequence[0].r;
                this.state.writingLocation.c = this.gameConfig.writingSequence[0].c;
                if (this.checkCell(this.state.writingLocation.r, this.state.writingLocation.c).indexOf("input")<0) { // if the first cell happens to be a given one, then move to the next already now
                       this.goToNextInSequence();
                }
            }
            this.state.hasLoaded = true;
        },
        /* UI methods */
        toggleDarkMode: function() {
            if (!this.generalConfig.useDarkMode)
                document.body.classList.remove('darkMode');  
            else 
                document.body.classList.add('darkMode');
        },
        showAlert: function(error, metadata) {
            if (error=='onlyCorrectWords') {
                if (!this.state.threwAlert) { // don't throw it twice while the alert is still showing
                    this.state.alert.text = this.localise.moreThanWords[this.uiLanguage] + " " + this.gameConfig.maxBadWords + " " + this.localise.onlyCorrectWords[this.uiLanguage]+": <br />"+metadata;
                    this.state.alert.type = 'error';
                    this.state.threwAlert = true;
                }
            } else if (error=='missingLetters') {
                this.state.alert.text = this.localise.missingLetters[this.uiLanguage];
                this.state.alert.type = 'error';
                this.state.threwAlert = true;
            }  else {
                return; 
            }
            // show info box for a bit
            this.state.isPaused = true;
            setTimeout(() => { this.state.threwAlert = false; this.state.isPaused = false; },this.generalConfig.timeout*1000);
        },
        showWindow: function(windowbox) {
            this.state.isPaused = true;
            document.getElementById(windowbox).style.height = "100%";
            document.getElementById(windowbox).style.top = "0";
            document.getElementById(windowbox).style.opacity = 1;
            document.getElementById(windowbox).style.pointerEvents = 'auto';


        },
        closeWindow: function(windowbox) {
            this.state.isPaused = false;
        
            document.getElementById(windowbox).style.height = "50%";
            document.getElementById(windowbox).style.top = "50%";
            document.getElementById(windowbox).style.opacity = 0;
            document.getElementById(windowbox).style.pointerEvents = 'none'; // to allow click-through since it's halfway

        },
        /* Helper functions */

        reverseIndex: function(value, arr) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i]==value) { 
                    return i;
                }
            }
            return -1;
        },
        countLetter: function(letter,text) {
            let count = 0;
            for (let i =0; i<text.length;i++) {
                if (text[i]==letter) {
                    count += 1;
                }
            }
            return count;
        },
        checkCell: function(row, column) { // a method to check the current state of a given cell
            for (let i = 0; i < this.currentGame.givenCells.length; i++) {
                if (this.currentGame.givenCells[i].r == row && this.currentGame.givenCells[i].c == column) { 
                    return 'given'; // locked in cell
                }
            }
            for (let i = 0; i < this.currentGame.correctCells.length; i++) {
                if (this.currentGame.correctCells[i].r == row && this.currentGame.correctCells[i].c == column) { 
                    return 'correct'; // locked in cell
                }
            }
            for (let i = 0; i < this.currentGame.existsInWordCells.length; i++) {
                if (this.currentGame.existsInWordCells[i].r == row && this.currentGame.existsInWordCells[i].c == column) { 
                    return 'existsInWord'; // exists
                }  
            }

            for (let i = 0; i < this.currentGame.existsCells.length; i++) {
                if (this.currentGame.existsCells[i].r == row && this.currentGame.existsCells[i].c == column) { 
                    return 'exists'; // exists
                }  
            }

            for (let i = 0; i < this.currentGame.doesntExistCells.length; i++) {
                if (this.currentGame.doesntExistCells[i].r == row && this.currentGame.doesntExistCells[i].c == column) { 
                    return 'doesntExist'; // doesn't exist
                }  
            }

            for (let i = 0; i < this.currentGame.currentUserInput.length; i++) {
                if (this.currentGame.currentUserInput[i].r == row && this.currentGame.currentUserInput[i].c == column) { 
                    return 'input'; // is input
                }  
            }

            return 'inputOrWrong';
        },
        isCellSelected: function(row,column) {
            if (this.state.writingLocation.r == row && this.state.writingLocation.c ==column) return true;
            else return false;
        },
        getInputAtCell: function(row,column) {
            for (let i = 0; i < this.currentGame.currentUserInput.length; i++) {
                if (this.currentGame.currentUserInput[i].r == row && this.currentGame.currentUserInput[i].c == column) { 
                    return this.currentGame.currentUserInput[i].t; // exists
                }  
            }

            // currentGame.correctColumnWords[reverseIndex(column,gameConfig.columns)][row-1]
            // currentGame.correctRowWords[reverseIndex(row,gameConfig.rows)][column-1]
            return "";
        },

        getCorrectSolutionAtCell:function(row,column) {
             for (let i = 0; i < this.currentGame.correctSolution.length; i++) {
                if (this.currentGame.correctSolution[i].r == row && this.currentGame.correctSolution[i].c == column) { 
                    return this.currentGame.correctSolution[i].t; // exists
                }  
            }

            // currentGame.correctColumnWords[reverseIndex(column,gameConfig.columns)][row-1]
            // currentGame.correctRowWords[reverseIndex(row,gameConfig.rows)][column-1]
            return "";
        },


        // check if cell exists and is addressable
        isCellAvailable: function(row, column) {
            for (let cell of this.currentGame.correctSolution) { 
                if (row == cell.r && column == cell.c) {
                    return true;
                }
            }

            return false;
        },
        resetEverything: function() {
            this.state.isPlaying = false;
            

            this.state.hasWon = false;
            this.state.hasLost = false;
            this.state.solutionCheckable = false; // checkable only when all squares have input
            //this.state.isPaused = false;

            this.currentGame.correctCells = []; // reset
            this.currentGame.existsCells = []; // reset
            this.currentGame.existsInWordCells = []; // reset
            this.currentGame.doesntExistCells = [];
            this.currentGame.givenCells = []; // reset

            this.currentGame.doesntExistLetter = ''; // ONLY emptied on new game
            this.currentGame.allInstancesFoundLetter = ''; // ONLY emptied on new game
            this.currentGame.existInWordLetter = new Set();
            this.currentGame.existLetter = new Set();
            

            this.currentGame.tries = 0;
            this.state.writingLocation = {r:1,c:1};
            this.state.wasJustInput = {r:-1,c:-1};
            this.currentGame.currentUserInput = [];
        },

        generateCorrectSolution: function() { // this may override
            this.currentGame.correctSolution = []; 

            this.logDebug("Generating solution");

            // for each column
            for (let c of this.gameConfig.columns) {
                for (let ri = 1; ri < this.gameConfig.height+1;ri++) {
                    this.currentGame.correctSolution.push({r:ri, c:c, t: this.currentGame.correctColumnWords[this.reverseIndex(c, this.gameConfig.columns)][ri-1]});
                }
            }
            for (let r of this.gameConfig.rows) {
                for (let ci = 1; ci < this.gameConfig.width+1;ci++) {
                    if (!this.gameConfig.columns.includes(ci)) // if the column is defined, then r,ci has already been pushed in in the loop above
                        this.currentGame.correctSolution.push({r:r, c:ci, t: this.currentGame.correctRowWords[this.reverseIndex(r, this.gameConfig.rows)][ci-1]});
                }
            }
            this.logDebug("Correct solution: ");
            this.logDebug(this.currentGame.correctSolution);
        },
        createNewGame: function() {
            // resetScores();
            
            if (this.state.blockNewGame) return; // wait until release of blockNewGame
            this.resetEverything();


            if (this.selectWords()) {
                 // if all row words are actual words ('' is returned when no matching word is found)
                    // create the board and correct solution
                    this.generateCorrectSolution();

                    this.logDebug("Succeeded in generating solution");

                    // todo: select the given cells

                    if (this.gameConfig.clues>= (this.gameConfig.rows.length*this.gameConfig.width)+this.gameConfig.columns.length*this.gameConfig.columns) {
                        this.logDebug("ASSERTION ERROR the number of clues exceed the number of available cells")
                    }

                    
                    for (let i = 0; i < this.gameConfig.clues;i++) {
                        let uniqueSelection = false;
                        this.logDebug("CLUE SELECT " + i);
                        let tries = 0;
                        while (!uniqueSelection) {
                            uniqueSelection = true;
                            let randomindex = Math.floor(Math.random() * this.currentGame.correctSolution.length); // add one since cell numbers start at 1
                            let putativeR = this.currentGame.correctSolution[randomindex].r;
                            let putativeC = this.currentGame.correctSolution[randomindex].c;
                            let putativeT = this.currentGame.correctSolution[randomindex].t;
                            
                            for (let cell of this.currentGame.givenCells) {
                               
                                if (cell.r == putativeR  && cell.c == putativeC) {
                                    uniqueSelection = false;
                                    break;
                                } // hold r, if c+-1 // avoid adjacent cells
                                else if (cell.r == putativeR  && (cell.c == putativeC+1 || cell.c == putativeC-1)) {
                                    uniqueSelection = false;
                                    break;
                                } // hold c, if r+-1
                                else if ( 
                                    ( cell.r == putativeR+1 || cell.r == putativeR-1) && 
                                    cell.c == putativeC ) {
                                    uniqueSelection = false;
                                    break;
                                }
                            }
                            tries++;
                            if (uniqueSelection) {
                                this.currentGame.givenCells.push({r: putativeR,c:putativeC,t:putativeT}); 
                            }
                            if (tries>50) {
                                this.logDebug("ASSERTION ERROR, tries exceeded");
                                break;
                            }
                        }
                    }

                    this.logDebug("Given cells");
                    this.logDebug(this.currentGame.givenCells);
                    // double check which letters are already all used
                    for (let givenCell of this.currentGame.givenCells) {
                        if (this.checkIfAllLockedIn(givenCell.t)) { 
                            //this.allInstancesFoundLetter.add(givenCell.t); 
                            if (this.currentGame.allInstancesFoundLetter.indexOf(givenCell.t)<0) {
                                this.currentGame.allInstancesFoundLetter=this.currentGame.allInstancesFoundLetter+givenCell.t;
                            }
                        
                        }
                    }
                    if (this.cheatMode) this.doCheat();

                    this.state.isPlaying = true;
                    // careful to avoid passing by reference; full copy
                    this.state.writingLocation.r = this.gameConfig.writingSequence[0].r;
                    this.state.writingLocation.c = this.gameConfig.writingSequence[0].c;
                    if (this.checkCell(this.state.writingLocation.r, this.state.writingLocation.c).indexOf("input")<0) { // if the first cell happens to be a given one, then move to the next already now
                       this.goToNextInSequence();
                    }
            } // if this failed, it should show an error message

            this.additionalUserStats.startedGames += 1;
            this.saveAdditionalUserStats();
            this.saveCurrentGame();

        },
        selectWords: function() {
            let triedTimes = 0;
            let allWordsFound = false;

            if (this.gameConfig.wordSelectionOrder.length != (this.gameConfig.columns.length+this.gameConfig.rows.length)) {
                this.logDebug("ASSERTION ERROR: order.length is not the sum of columns and rows");
            }

            while (!allWordsFound) {
                this.currentGame.correctColumnWords = []; // reset
                this.currentGame.correctColumnLanguages = []; // reset
                this.currentGame.correctRowWords = []; // reset
                this.currentGame.correctRowLanguages = []; // reset

                let usedColumns = 0;
                let usedRows = 0;
                let usedLanguages = {};
                let anagramLetters = [];
                for (let order of this.gameConfig.wordSelectionOrder) {
                    this.logDebug("WORD SELECTION");
                    // first check which letters are locked
                    let lettersInWord = [];
                    let wordLength = (order=='c')?this.gameConfig.height: this.gameConfig.width;
                    for (let j = 0; j < wordLength; j++) {
                        lettersInWord.push(''); // empty string 
                    }
                    this.logDebug("word length: " + wordLength + " and type " + order);
                    this.logDebug(lettersInWord);

                    if (order == 'c') { // if column, only row words can ovlerap
                        for (let r = 0; r < usedRows; r++) {
                            lettersInWord[this.gameConfig.rows[r]-1] = this.currentGame.correctRowWords[r][this.gameConfig.columns[usedColumns]-1]; // -1 since grid starts at 1
                        }
                    }
                    else if (order == 'r') { // if row, only column words can ovlerap
                        for (let c = 0; c < usedColumns; c++) {
                            lettersInWord[this.gameConfig.columns[c]-1] = this.currentGame.correctColumnWords[c][this.gameConfig.rows[usedRows]-1]; // -1 since grid starts at 1
                        }
                    }

                    this.logDebug("Current content");
                    this.logDebug(this.currentGame.correctRowWords);
                    this.logDebug(this.currentGame.correctColumnWords);
                    this.logDebug(lettersInWord);

                    this.logDebug("Getting word");
                    // FIX for several languages
                    let language = this.gameConfig.languages[0]; // default: one language

                    // if there are multiple languages, 
                    if (this.gameConfig.languages.length>1) {
                        this.logDebug("Multilingual selection");
                        // default behaviour: randomly selected
                        if ('distribution' in this.gameConfig) {
                            this.logDebug("Distribution detected");
                            let allUsed = true;
                            let tries = 0;
                            while (allUsed) {
                                language = this.gameConfig.languages[Math.floor(Math.random() * this.gameConfig.languages.length)];
                                if (language in usedLanguages) {
                                    if (usedLanguages[language]>=this.gameConfig.distribution[language]) { // already in there, redo
                                        this.logDebug("distribution exceeded for " + language + " " + usedLanguages[language]);
                                    } else {
                                        usedLanguages[language]+=1;
                                        allUsed = false; 
                                    }
                                } else {
                                    usedLanguages[language] = 1;
                                    allUsed = false; 
                                }
                                tries++;
                                if (tries>50) {
                                    this.logDebug("Error in language distribution logic");
                                    break;
                                }
                            }
                        } else {
                            language = this.gameConfig.languages[Math.floor(Math.random() * this.gameConfig.languages.length)];
                        }
                    }

                    let word = wordStore.getWord(language, ((order=='c')?this.gameConfig.height: this.gameConfig.width) ,lettersInWord,this.currentGame.correctColumnWords.concat(this.currentGame.correctRowWords),anagramLetters);
                    // anagram letters
                    if (this.gameConfig.anagramLetters && anagramLetters.length == 0) { // only do it once, based on the first word
                        this.logDebug("generating anagram letters");
                        let usedIndices = new Set();
                        while(usedIndices.size < this.gameConfig.anagramLetters) { // select random letters to use
                            let newInd = Math.floor(Math.random() * ((order=='c')?this.gameConfig.height: this.gameConfig.width));
                            usedIndices.add(newInd); // since it's a set, it will repeat until there are three letters in there
                            
                        }
                        for (let i of usedIndices) {
                            anagramLetters.push(word[i]);
                        }
                    }
                    this.logDebug("Got the word: " + word);
                    this.logDebug("Anagram letters, if any: ");
                    this.logDebug(anagramLetters);


                    if (order == 'c') { 
                        this.currentGame.correctColumnWords.push(word);
                        this.currentGame.correctColumnLanguages.push(language);
                        usedColumns++;
                    }
                    else if (order == 'r') { 
                        this.currentGame.correctRowWords.push(word);
                        this.currentGame.correctRowLanguages.push(language);
                        usedRows++;
                    }
                }

                triedTimes++;
                if (!this.currentGame.correctRowWords.includes('')&&!this.currentGame.correctColumnWords.includes('')) {
                    allWordsFound = true;
                    this.logDebug(this.currentGame.correctColumnWords + " " + this.currentGame.correctRowWords);
                    this.logDebug(this.currentGame.correctColumnLanguages + " " + this.currentGame.correctRowLanguages);
                    this.logDebug("succeeded after " + triedTimes + " times.");
                    this.logDebug(usedLanguages);
                    return true;
                }
                
                if (triedTimes>this.gameConfig.maxTries) {
                    break;
                }
            }

            this.logDebug("failed to find word configuration after max tries.");
            return false;
        },
        // check if the specific letter exists in the specific word it appears
        checkIfLetterIsUsedInWord: function(row,column,letter) {
            if (letter =="") return false;

            let count = 0;
            // first, find the word - the cells with multiple words could be interesting...
            // given the two-dimensional layout: there can be at most two intersecting words in any given cell
            // therefore, grab a column word if there is one for the given column
            // and grab a row word if there is one for the given row
            let columnWord= "";
            let columnI = -1;
            for (let ci in this.gameConfig.columns) {
                    if (column == this.gameConfig.columns[ci]) {
                        columnWord = this.currentGame.correctColumnWords[ci];
                        columnI = column; // thew 
                        break;
                    }
            }
            let rowWord = "";
            let rowI = -1;
            for (let ri in this.gameConfig.rows) {
                    if (row == this.gameConfig.rows[ri]) {
                        rowWord = this.currentGame.correctRowWords[ri];
                        rowI = row;
                        break;
                    }
            }

            // count the number of that specific letter
            // in corner case, the letter would be counted twice, but not an issue here given that the corner cell wouldn't be checked if locked in or given anyway
            count += this.countLetter(letter, columnWord);
            count += this.countLetter(letter, rowWord);

            this.logDebug("exist in specific word: the letter " + letter + " total " + count);

            // subtract the one's already locked in as given word
            for (let correct of this.currentGame.correctCells) {
                if (correct.r == row && correct.c == column && correct.t == letter) { // corner case, but as noted this can't be ever be the case
                    this.logDebug("Corner cell is listed as correct, but is presently checked for user input, assertion error");
                    count -=1;
                } 
                else if (correct.r == row && correct.t == letter && row == rowI) { // EITHER row or column must match, not both; further, row must contain the word
                    count -=1;
                }
                else if (correct.c == column && correct.t == letter && column == columnI) { // if column, then there must be a word in that column index
                    count -=1;
                }
            }

            for (let given of this.currentGame.givenCells) {
                if (given.r == row && given.c == column && given.t == letter) { // corner case, but as noted this can't be ever be the case
                    this.logDebug("Corner cell is listed as given, but is presently checked for user input, assertion error");
                    count -=1;
                } 
                else if (given.r == row && given.t == letter && row == rowI) { // EITHER row or column must match, not both
                    count -=1;
                }
                else if (given.c == column && given.t == letter && column == columnI) {
                    count -=1;
                }
            }

            
            for (let existsInWord of this.currentGame.existsInWordCells) {
                if (existsInWord.r == row && existsInWord.c == column && existsInWord.t == letter) { // corner case, but as noted this can't be ever be the case
                    
                    count -=1;
                } 
                else if (existsInWord.r == row && existsInWord.t == letter && row == rowI) { // EITHER row or column must match, not both
                    count -=1;
                }
                else if (existsInWord.c == column && existsInWord.t == letter && column == columnI) {
                    count -=1;
                }
            }

            // could this inadvertedly be counted twice if it's given in both row and column?
            // no: because this cell wouldn't be checked for input if that was the case, it's already locked in and can't be edited by the user anymore

            // if sum more than zero, return true
            this.logDebug("exist in specific word: the letter " + letter + " diff " + count + " ("+(count > 0)+")");
            if (count > 0) return true;
            else return false;
        },
        countAllInstances: function(letter) {
            if (letter =="") return 0;
            let count = 0;
            for (let solution of this.currentGame.correctSolution) {
                if (solution.t == letter) count+=1;
            } // all the time the letter exists
            return count;
        },
        checkIfLetterExists: function(letter) {
            if (letter =="") return false;
            let count = this.countAllInstances(letter);
            return (count >= 1); // if 0, the letter is not used at all
        },
        checkIfAllLockedIn: function(letter) {
            if (letter =="") return false;
            let count = this.countAllInstances(letter);
            for (let correct of this.currentGame.correctCells) {
                if (correct.t == letter) count-=1;
            } // 
            for (let given of this.currentGame.givenCells) {
                if (given.t == letter) count-=1;
            }
            // if all are locked in, then count == 0
            // if the letter is used elsewhere, the count >0
            // if the count < 0, something has gone horribly wrong
            if (count <= 0) return true;
            else return false;
        },
        checkIfLetterIsUsed: function(letter) { // if letter is input and at least one such letter that is not already locked in is used, then it exists
            if (letter =="") return false;
            let count = this.countAllInstances(letter);

            this.logDebug("the letter " + letter + " in correct " + count);
            for (let correct of this.currentGame.correctCells) {
                if (correct.t == letter) count-=1;
            } // 
            for (let given of this.currentGame.givenCells) {
                if (given.t == letter) count-=1;
            }

            for (let existsInWord of this.currentGame.existsInWordCells) { // if the letter has already been marked as exist in a word, don't count it again
                this.logDebug("exists in word, remove");
                if (existsInWord.t == letter) count-=1;
            }

            for (let exists of this.currentGame.existsCells) { // if the letter already exists, ensure that there must be multiple in input for it to trig
                if (exists.t == letter) count-=1;
            }

            this.logDebug("the letter " + letter + " diff " + count);
            if (count > 0) return true;
            else return false;
        },

        checkCurrentSolution: function() {
            // for each column: check the content of each cell
           // let words = [];
           if (this.state.hasWon||this.state.hasLost) return; // do not check

            // precheck, that all input has been entered
            for (let solution of this.currentGame.correctSolution) { 
                if (this.checkCell(solution.r,solution.c)=="correct"||this.checkCell(solution.r,solution.c)=="given") continue;
                let userInput = this.getInputAtCell(solution.r,solution.c);
                if (userInput=="") {
                    this.state.requestedInput = true;
                    setTimeout(() => {this.state.requestedInput = false;  },this.generalConfig.missingOutputTimeout*1000);
                    
                    return; // do not allow 
                }
            }

            if (this.generalConfig.onlyCorrectWords) {
                let onlyValidWords = true;
                let badWords = "";
                let wordsValidity = {};
                let badCount = 0; // max allowed badwords
                for (let c of this.gameConfig.columns) {
                    let cWord = '';
                    for (let r = 1; r < this.gameConfig.height+1;r++) {
                        let ch = this.getInputAtCell(r,c);
                        cWord += (ch==''?this.getCorrectSolutionAtCell(r,c):ch);
                    }
                    for (let ling of this.gameConfig.languages) {
                        
                        if (!wordStore.isWordInList(ling, cWord)) {
                            if (!(cWord in wordsValidity)&&wordsValidity[cWord]!=true) { // if marked once as true, do not overrule
                                onlyValidWords = false;
                                wordsValidity[cWord] = false;
                                badCount += 1;
                            }
                        } else {
                            if ((cWord in wordsValidity) && wordsValidity[cWord]==false) badCount -= 1; // subtract if already added
                            wordsValidity[cWord] = true;
                        }
                        this.logDebug("Checking for " + cWord + " " + onlyValidWords);
                    }
                }

                for (let r of this.gameConfig.rows) {
                    let rWord = '';
                    for (let c = 1; c < this.gameConfig.width+1;c++) {
                        let ch = this.getInputAtCell(r,c);
                        rWord += (ch==''?this.getCorrectSolutionAtCell(r,c):ch);
                    }
                    for (let ling of this.gameConfig.languages) {
                        
                        if (!wordStore.isWordInList(ling, rWord)) {
                            if (!(rWord in wordsValidity) && wordsValidity[rWord]!=true) {
                                onlyValidWords = false;
                                wordsValidity[rWord] = false;
                                badCount += 1;
                            }
                        } else {
                            if ((rWord in wordsValidity) && wordsValidity[rWord]==false) badCount -= 1; // subtract if already added
                            wordsValidity[rWord] = true;
                        }
                        this.logDebug("Checking for " + rWord + " " + onlyValidWords);
                    }
                }
                badWords = "";
                for (let vdx in wordsValidity) {
                    if (wordsValidity[vdx]==false)
                    badWords += vdx + ", ";
                }
                this.logDebug(wordsValidity);
                badWords = badWords.substring(0, badWords.length-2); // remove the last two characters
                this.logDebug(badCount +" " + this.gameConfig.maxBadWords);
                if (!onlyValidWords && badCount > this.gameConfig.maxBadWords) {
                    this.showAlert('onlyCorrectWords',badWords);
                    return; // break
                }

            }

           let isWin = true;
            this.currentGame.existsCells = []; // these are cleared out and rechecked
            this.currentGame.doesntExistCells = [];
             this.currentGame.existsInWordCells = []; // reset
            // todo: check that inputs are valid words

            this.currentGame.existInWordLetter = new Set();
            this.currentGame.existLetter = new Set();
            // this one is only emptied on new game; once you have spotted a letter that doesn't exist in at all, mark it
            //this.currentGame.doesntExistLetter = new Set();



           for (let solution of this.currentGame.correctSolution) {
               if (this.checkCell(solution.r,solution.c)=="correct"||this.checkCell(solution.r,solution.c)=="given") continue; // no need to compare
               let userInput = this.getInputAtCell(solution.r,solution.c);
               let correctSolution = this.getCorrectSolutionAtCell(solution.r,solution.c);
               if (userInput!=correctSolution) {
                   isWin = false;
                    // if letter instances are 0
               } else { // add to corrects
                this.currentGame.correctCells.push({r:solution.r,c:solution.c,t:userInput});
                
               }
           }
           // second pass, to punch in the letters not in correct positions
           // have to do several passes to ensure the different Cells is up to date first

            // mark the ones that exist in words first
           for (let solution of this.currentGame.correctSolution) {
               if (this.checkCell(solution.r,solution.c)=="correct"||this.checkCell(solution.r,solution.c)=="given") continue; // no need to compare
               let userInput = this.getInputAtCell(solution.r,solution.c);
               let correctSolution = this.getCorrectSolutionAtCell(solution.r,solution.c);
               if (userInput!=correctSolution) { //mark them up
                   if (this.checkIfLetterIsUsedInWord(solution.r, solution.c, userInput)) { 
                       this.logDebug("letter is used: pushed in");
                       this.currentGame.existsInWordCells.push({r:solution.r, c:solution.c, t:userInput});
                      // this.currentGame.existInWordLetter.add(userInput); ignore for the moment
                    }
               }
           }

        // then, do a pass for whether it otherwise exists
           for (let solution of this.currentGame.correctSolution) {
               if (this.checkCell(solution.r,solution.c)=="correct"||this.checkCell(solution.r,solution.c)=="given") continue; // no need to compare
               let userInput = this.getInputAtCell(solution.r,solution.c);
               let correctSolution = this.getCorrectSolutionAtCell(solution.r,solution.c);
               if (userInput!=correctSolution) { //mark them up
                   if (this.checkIfLetterIsUsed(userInput)) { 
                       this.currentGame.existsCells.push({r:solution.r, c:solution.c, t:userInput});
                      // this.currentGame.existLetter.add(userInput); ignore for the moment
                       }
                   else { // this behaves incorrectly: now only mark letters that DO NOT exist at all in the input, then it's fine
                       this.currentGame.doesntExistCells.push({r:solution.r, c:solution.c, t:userInput});
                     //  this.currentGame.doesntExistLetter.add(userInput);
                       }
               }
           }

            // update doesntExistLetter and allInstancesFound
            // fifth pass over the same array, but...
            for (let solution of this.currentGame.correctSolution) {
                let userInput = this.getInputAtCell(solution.r,solution.c);
                if (userInput!='') {
                    if (!this.checkIfLetterExists(userInput)) {
                        if (this.currentGame.doesntExistLetter.indexOf(userInput)<0) {
                            this.currentGame.doesntExistLetter = this.currentGame.doesntExistLetter + userInput;
                        }
                    }

                    else if (this.checkIfAllLockedIn(userInput)) {
                        if (this.currentGame.allInstancesFoundLetter.indexOf(userInput)<0) {
                            this.currentGame.allInstancesFoundLetter=this.currentGame.allInstancesFoundLetter+userInput;
                        }
                    }
                
                    
                }
            }
           

            this.logDebug("exists in word count: " +  this.currentGame.existsInWordCells.length);
           // also, check if word is in wordlist...

            this.currentGame.tries+=1;

            // make updates here...

            this.state.wasJustInput = {r:-1,c:-1}; // reset to avoid any animation shenanigans


            this.saveCurrentGame(); // save the current state

           if (isWin) this.onVictory();
            else if (this.currentGame.tries>=this.gameConfig.maxTries) this.onLoss();

        },

        saveAfterGame: function() {
            this.saveUserStats();
            this.saveAdditionalUserStats();
            this.deleteCurrentGame();
        },
        onVictory: function() {
            this.state.hasWon = true;
            this.state.isPlaying = false;
            this.userStats.streak+=1;
            if (this.userStats.streak>this.userStats.maxStreak) {
                this.userStats.maxStreak=this.userStats.streak;
            }
            this.userStats.wins+=1;

            if (this.currentGame.tries < this.userStats.fastestSolution) {
                this.userStats.fastestSolution = this.currentGame.tries;
            }

            // update states for solve distribution
            if ((''+this.currentGame.tries) in this.additionalUserStats.solves) {
                this.additionalUserStats.solves[''+this.currentGame.tries]+=1;
            } else {
                this.additionalUserStats.solves[''+this.currentGame.tries]=1;
            }

            this.saveAfterGame();

            // give a moment to display the solution
            this.state.blockNewGame = true;
            setTimeout(() => {
                this.showWindow('statsBox'); this.state.blockNewGame = false;
                document.getElementById("newGameButton").focus();
                
            },this.generalConfig.winTimeout*1000);
            //this.showWindow('statsbox');
        },

        onLoss: function() { // called when the user has lost
            this.state.hasLost = true;
            this.state.isPlaying = false;
            this.userStats.streak=0;
            this.userStats.losses+=1;
            
            this.saveAfterGame();

            // give a moment to display the solution
            this.state.blockNewGame = true; // to prevent rapid clicks
            setTimeout(() => {
                this.showWindow('statsBox');
                this.state.blockNewGame = false;
                document.getElementById("newGameButton").focus();
            }, this.generalConfig.lossTimeout*1000);

        }, // max tries have been reached, end current streak, update loss numbers


        // onkey or the on screen keyboard
        // $emitted from keyboard
        onKeyboardInput: function(key) { // check that the characters are allowed, then push to cell input
            this.logDebug(key);

            if (!this.state.isPaused && (this.state.hasWon||this.state.hasLost) && (key=="Enter"))  {
                this.createNewGame();
                return;
            }
            // if state is playing, too
            // todo: the keys used for starting a new game and unpausing are of course allowed
            if (!this.state.isPlaying||this.state.isPaused||this.state.hasWon||this.state.hasLost) return; 

            

            if (key.indexOf("Arrow")!=-1) {
                this.onManualNavigation(key);
                return;
            }
            else if (this.gameConfig.allowedCharacters.indexOf(key.toLowerCase())>=0) {
                this.onCellInput(key);
                return;
            }
            else if (key == "Delete"||key == "Backspace") {
                this.deleteCellInput();
            }
            else if (key == "Enter") {
                this.checkCurrentSolution();
            }

        }, 
        // hard rule, locked in cells and given cells cannot be edited
        onCellInput: function(key) { // when data is inputted to a cell - update internal state, and navigate to next cell
            // first, check that cell is either input or wrong
            let cellState = this.checkCell(this.state.writingLocation.r,this.state.writingLocation.c);
            if (cellState == "input"||cellState=="inputOrWrong"||cellState=="exists"||cellState=="existsInWord"||cellState=="doesntExist") {
                this.updateCellInput(this.state.writingLocation.r,this.state.writingLocation.c,key);

                this.state.wasJustInput = {r: this.state.writingLocation.r, c: this.state.writingLocation.c}; 
                // reset it so as to make input animation possible again
                setTimeout(() => {this.state.wasJustInput = {r: -1, c: -1};  },this.generalConfig.inputTimeOut*1000);
               // console.log(this.state.wasJustInput);

               if (this.generalConfig.navigateNext) this.goToNextInSequence();
            }

            // check that all the input has been entered
            let allInput = true;
            for (let solution of this.currentGame.correctSolution) { 
                if (this.checkCell(solution.r,solution.c)=="correct"||this.checkCell(solution.r,solution.c)=="given") continue;
                let userInput = this.getInputAtCell(solution.r,solution.c);
                if (userInput=="") {
                    allInput = false;
                    break;
                } // do not allow 
            }
            this.state.solutionCheckable = allInput;
            
            this.logDebug("State is checkable");

        }, 
        goToNextInSequence: function() { // when user have entered input in a cell, move automatically to the next one in the predefined sequence

            let index = -1;

            // first, find the current index
            for (let i = 0; i < this.gameConfig.writingSequence.length; i++) {
                    if (this.state.writingLocation.r == this.gameConfig.writingSequence[i].r && this.state.writingLocation.c == this.gameConfig.writingSequence[i].c) {
                        index = i; 
                        break;
                    }
                }

            for (let i = index+1; i < this.gameConfig.writingSequence.length; i++) {
                if ( (this.checkCell(this.gameConfig.writingSequence[i].r,this.gameConfig.writingSequence[i].c)!="given") &&
                (this.checkCell(this.gameConfig.writingSequence[i].r,this.gameConfig.writingSequence[i].c)!="correct") ) {
                    this.state.writingLocation.r = this.gameConfig.writingSequence[i].r;
                    this.state.writingLocation.c = this.gameConfig.writingSequence[i].c;
                    return; // done
                }
            }

            for (let i = 0; i < index; i++) {
                if ( (this.checkCell(this.gameConfig.writingSequence[i].r,this.gameConfig.writingSequence[i].c)!="given") &&
                (this.checkCell(this.gameConfig.writingSequence[i].r,this.gameConfig.writingSequence[i].c)!="correct") ) {
                    this.state.writingLocation.r = this.gameConfig.writingSequence[i].r;
                    this.state.writingLocation.c = this.gameConfig.writingSequence[i].c;
                    return; // done
                }
            }

            this.logDebug("No available cells found, staying put");
            // this means that only the current slot is available, do nothing
            return;
        },
        updateCellInput:function(row,column,key) { // assert: this function only called when cellState allows it
            let exists = false;
            for (let i = 0; i < this.currentGame.currentUserInput.length; i++) {
                if (this.currentGame.currentUserInput[i].r == row && this.currentGame.currentUserInput[i].c == column) { 
                   this.currentGame.currentUserInput[i].t = key; // exists
                   exists = true;
                   break;
                }  
            }
            if (!exists) {
                this.currentGame.currentUserInput.push({r:row,c:column,t:key});
            }

            // remove from exist list, so as to change color
            for(let exists of this.currentGame.existsCells) {
                if (exists.r == row && exists.c == column) {
                    let index = this.currentGame.existsCells.indexOf(exists);
                    this.currentGame.existsCells.splice(index, 1); // when you enter input, remove it from the exists list
                    
                    break;
                }
            }

            for(let existsInWord of this.currentGame.existsInWordCells) {
                if (existsInWord.r == row && existsInWord.c == column) {
                    let index = this.currentGame.existsInWordCells.indexOf(existsInWord);
                    this.currentGame.existsInWordCells.splice(index, 1); // when you enter input, remove it from the exists list
                    
                    break;
                }
            }

            for(let doesntExist of this.currentGame.doesntExistCells) {
                if (doesntExist.r == row && doesntExist.c == column) {
                    let index = this.currentGame.doesntExistCells.indexOf(doesntExist);
                    this.currentGame.doesntExistCells.splice(index, 1); // when you enter input, remove it from the doesnt' exist list
                    
                    break;
                }
            }
        },
        deleteCellInput:function() { // if user has clicked the delete key
            let cellState = this.checkCell(this.state.writingLocation.r,this.state.writingLocation.c);
            if (cellState == "input"||cellState=="input-or-wrong"||cellState=="exists"||cellState=="doesntExist") {
                for (let i = 0; i < this.currentGame.currentUserInput.length; i++) {
                    if (this.currentGame.currentUserInput[i].r == this.state.writingLocation.r && this.currentGame.currentUserInput[i].c == this.state.writingLocation.c) { 
                        this.currentGame.currentUserInput[i].t = "";
                        break;
                    }  
                } 
            }

            this.state.solutionCheckable = false; // by definition, because now there's an empty cell

            for (let doesntExist of this.currentGame.doesntExistCells) { // remove from doesn't exist ist
                if (doesntExist.r == this.state.writingLocation.r && doesntExist.c == this.state.writingLocation.c) {
                    let index = this.currentGame.doesntExistCells.indexOf(doesntExist);
                    this.currentGame.doesntExistCells.splice(index, 1); // when you enter input, remove it from the doesnt' exist list
                    
                    break;
                }
            }

            for(let exists of this.currentGame.existsCells) {
                if (exists.r == this.state.writingLocation.r && exists.c == this.state.writingLocation.c) {
                    let index = this.currentGame.existsCells.indexOf(exists);
                    this.currentGame.existsCells.splice(index, 1); // when you enter input, remove it from the exists list
                    
                    break;
                }
            }

            for(let existsInWord of this.currentGame.existsInWordCells) {
                if (existsInWord.r == this.state.writingLocation.r && existsInWord.c == this.state.writingLocation.c) {
                    let index = this.currentGame.existsInWordCells.indexOf(existsInWord);
                    this.currentGame.existsInWordCells.splice(index, 1); // when you enter input, remove it from the exists list
                    
                    break;
                }
            }


        },
        onManualNavigation: function(key) { // select cell using arrow keys
            let putativeR = this.state.writingLocation.r;
            let putativeR2 = this.state.writingLocation.r;

            let putativeC = this.state.writingLocation.c;
            let putativeC2 = this.state.writingLocation.c;

            switch(key) {
                case "ArrowDown": // move downwards if possible
                    putativeR = this.state.writingLocation.r+1; 
                    // check next available row in the event r+1 doesn't have a cell
                    for (let row of this.gameConfig.rows) { //
                        if (row>putativeR2) {
                            putativeR2 = row;
                            break;
                        }
                    }
                    
                    break;
                case "ArrowUp": // move downwards if possible
                    putativeR = this.state.writingLocation.r-1; 

                    for (let row of this.gameConfig.rows) {
                        if (row<putativeR2) {
                            putativeR2 = row;
                            break;
                        }
                    }

                    break;
                case "ArrowLeft": // move downwards if possible
                    putativeC = this.state.writingLocation.c-1; 

                    for (let column of this.gameConfig.columns) { //
                        if (column<putativeC2) {
                            putativeC2 = column;
                            break;
                        }
                    }
                    break;

                case "ArrowRight": // move downwards if possible
                    putativeC = this.state.writingLocation.c+1; 

                    for (let column of this.gameConfig.columns) { //
                        if (column>putativeC2) {
                            putativeC2 = column;
                            break;
                        }
                    }

                    break;
                default:
                    break;
            }
            if (this.isCellAvailable(putativeR, putativeC)) {
                this.state.writingLocation= {r:putativeR, c:putativeC};
            }
            else if (this.isCellAvailable(putativeR2, putativeC)) {
                this.state.writingLocation= {r:putativeR2, c:putativeC};
            }
            else if (this.isCellAvailable(putativeR, putativeC2)) {
                this.state.writingLocation= {r:putativeR, c:putativeC2};
            }
        }, // when the user uses arrow keys to select input cell

        selectCell: function(row,column) { // a function to mark a cell of selection based on the user click
            if (this.state.isPaused||!this.state.isPlaying) return;
            
            if (this.isCellAvailable(row,column)) {
                this.state.writingLocation = {r:row, c:column};
                return;
            }
                   
            this.logDebug("no cell was selected for " + row + " " + column + " ");
        }, // when the user selects a cell by pressing on it
    }
 }