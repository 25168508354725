export default {
    data: function() { return { 
        gameConfigs: {
            "bandwagon-5-6-en": { // default configuration for Bandwagon 6*5 ENGLISH, can be overriden by in the specific game instance
                name:'bandwagon',
                enabled: true,
                route:'classic',
                languages:['en'], // game languages
                width: 5,
                height: 6,
                columns: [1, 5], // which columns are to have a word vertically
                rows: [1, 6], // which rows are to have a word horisontally
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},
                {r: 2, c: 5},{r: 3, c: 5},{r: 4, c: 5},{r: 5, c: 5},{r: 6, c: 5},
                {r: 2, c: 1},{r: 3, c: 1},{r: 4, c: 1},{r: 5, c: 1},{r: 6, c: 1},
                {r: 6, c: 2},{r: 6, c: 3},{r: 6, c: 4},{r: 6, c: 5}],
                reportingCell: {r:5, c: 3},
                wordSelectionOrder: ['c','r','c','r'],
                maxTries: 10,
                clues: 7,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyz",
                keyboardLayout:"en",
                maxBadWords: 2,
                
            },
            "bandwagon-7-4-en": { // default configuration for Bandwagon 6*5 ENGLISH, can be overriden by in the specific game instance
                name:'bandwagon',
                enabled: true,
                variant: '7x4',
                route:'bandwagon74',
                languages:['en'], // game languages
                width: 7,
                height: 4,
                columns: [1, 7], // which columns are to have a word vertically
                rows: [1, 4], // which rows are to have a word horisontally
                heightAdjust: 1,
                widthAdjust: 3,
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},{r: 1, c: 6},{r: 1, c: 7},
                {r: 2, c: 7},{r: 3, c: 7},{r: 4, c: 7},
                {r: 2, c: 1},{r: 3, c: 1},{r: 4, c: 1},
                {r: 4, c: 2},{r: 4, c: 3},{r: 4, c: 4},{r: 4, c: 5},{r: 4, c: 6},{r: 4, c: 7}],
                reportingCell: {r:3, c: 4},
                wordSelectionOrder: ['c','r','c','r'],
                maxTries: 10,
                clues: 7,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyz",
                keyboardLayout:"en",
                maxBadWords: 2
            },
            "bandwagon-8-5-en": { // default configuration for Bandwagon 6*5 ENGLISH, can be overriden by in the specific game instance
                name:'bandwagon',
                enabled: false,
                variant:"8x5",
                route:'bandwagon85',
                languages:['en'], // game languages
                width: 8,
                height: 5,
                columns: [1, 8], // which columns are to have a word vertically
                rows: [1, 5], // which rows are to have a word horisontally
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},{r: 1, c: 6},{r: 1, c: 7},{r: 1, c: 8},
                {r: 2, c: 8},{r: 3, c: 8},{r: 4, c: 8},{r: 5, c: 8},
                {r: 2, c: 1},{r: 3, c: 1},{r: 4, c: 1},{r: 5, c: 1},
                {r: 5, c: 2},{r: 5, c: 3},{r: 5, c: 4},{r: 5, c: 5},{r: 5, c: 6},{r: 5, c: 7},{r: 5, c: 8}],
                reportingCell: {r:4, c: 7},
                wordSelectionOrder: ['c','r','c','r'],
                maxTries: 10,
                clues: 8,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyz",
                keyboardLayout:"en",
                maxBadWords: 3
            },
            "anagramia-5-3-en": { 
                name:'anagramia',
                enabled: true,
                route:'anagramia',
                languages:['en'], // game languages
                anagramLetters: 3, // number of letters needed to form the anagram
                width: 5,
                height: 4,
                columns: [], 
                rows: [1, 2, 3],
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},
                    {r: 2, c: 1},{r: 2, c: 2},{r: 2, c: 3},{r: 2, c: 4},{r: 2, c: 5},
                    {r: 3, c: 1},{r: 3, c: 2},{r: 3, c: 3},{r: 3, c: 4},{r: 3, c: 5}],
                reportingCell: {r:4, c: 3},
                wordSelectionOrder: ['r','r','r'],
                maxTries: 8,
                clues: 4,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyz",
                keyboardLayout:"en",
                maxBadWords: 1
            },
            "bandwagon-5-6-sv": {
                name:'bandwagon',
                enabled: true,
                route:'svenska',
                languages:['sv'], // game languages
                width: 5,
                height: 6,
                columns: [1, 5], // which columns are to have a word vertically
                rows: [1, 6], // which rows are to have a word horisontally
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},
                {r: 2, c: 5},{r: 3, c: 5},{r: 4, c: 5},{r: 5, c: 5},{r: 6, c: 5},
                {r: 2, c: 1},{r: 3, c: 1},{r: 4, c: 1},{r: 5, c: 1},{r: 6, c: 1},
                {r: 6, c: 2},{r: 6, c: 3},{r: 6, c: 4},{r: 6, c: 5}],
                reportingCell: {r:5, c: 3},
                wordSelectionOrder: ['c','r','c','r'],
                maxTries: 10,
                clues: 7,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyzåäö",
                keyboardLayout:"sv",
                maxBadWords: 2
            },
            "bandwagon-5-6-fi": {
                name:'bandwagon',
                enabled: false,
                languages:['fi'], // game languages
                route:'suomi',
                width: 5,
                height: 6,
                columns: [1, 5], // which columns are to have a word vertically
                rows: [1, 6], // which rows are to have a word horisontally
                writingSequence: [{r: 1, c: 1},{r: 1, c: 2},{r: 1, c: 3},{r: 1, c: 4},{r: 1, c: 5},
                {r: 2, c: 5},{r: 3, c: 5},{r: 4, c: 5},{r: 5, c: 5},{r: 6, c: 5},
                {r: 2, c: 1},{r: 3, c: 1},{r: 4, c: 1},{r: 5, c: 1},{r: 6, c: 1},
                {r: 6, c: 2},{r: 6, c: 3},{r: 6, c: 4},{r: 6, c: 5}],
                reportingCell: {r:5, c: 3},
                wordSelectionOrder: ['c','r','c','r'],
                maxTries: 10,
                clues: 7,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyzåäö",
                keyboardLayout:"sv",
                maxBadWords: 2
            },
            "bilingia-5-5-fi-sv": {
                name:'bilingia',
                enabled: true,
                languages:['fi','sv'], // game languages
                route:'bilingia',
                distribution: {"fi":1,"sv":1}, // words from each
                width: 5,
                height: 5,
                columns: [3], // which columns are to have a word vertically
                rows: [3], // which rows are to have a word horisontally
                writingSequence: [{r: 1, c: 3},{r: 2, c: 3},{r: 3, c: 3},{r: 4, c: 3},{r: 5, c: 3},
                {r: 3, c:1},{r: 3, c:2},{r: 3, c:3},{r: 3, c:4},{r: 3, c:5},],
                reportingCell: {r:2, c: 4},
                wordSelectionOrder: ['c','r'],
                maxTries: 6,
                clues: 3,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyzåäö",
                keyboardLayout:"sv",
                maxBadWords: 1
                
            },
            "eliminator-6-3-en": {
                name: "eliminator",
                enabled: false, // this affects the list of games
                languages: ['en'],
                route: 'eliminator',
                width: 6,
                height: 3,
                columns: [], 
                rows: [1, 2, 3],
                eliminatorWords: 1,
                wordSelectionOrder: ['r','r','r'],
                maxTries: 10,
                allowedCharacters: "abcdefghijklmnopqrstuvwxyz",
                keyboardLayout:"en",
                maxBadWords: 1,
            }
        }
    }
    }

}