<template>
    <div class="keyboardContainer">
        <div class="keyboard">
            <div v-for="row, index in config.keyboardRows[keyboardLayout].keys" v-bind:key="index" class="keyboardRowContainer">
                
                <div class="keyboardRow">
                    <div v-for="key, kindex in row" v-bind:key="kindex"
                    v-bind:class="[ 'key', (config.keyboardRows[keyboardLayout].spacings[index][kindex]!=10)?'key'+config.keyboardRows[keyboardLayout].spacings[index][kindex]:'',
                                    (key.indexOf('sp')>=0)?'hidden':checkLetter(key),
                                    ((key=='↵' && (flash))?'flashingKey':'')]"
                    v-on:click="onScreenInput(key)">
                        <span v-if="(showNew) && key=='↵'">
                            <button v-if="showNew" v-on:click="onScreenInput(key)" aria-label="Start new game">
                                <font-awesome-icon size="xl" icon="fa-solid fa-play-circle" /></button>
                        </span>
                        <span v-else-if="key=='del'"><font-awesome-icon size="lg" icon="fa-solid fa-backspace" /></span>
                        <span v-else-if="key=='↵'"><font-awesome-icon size="lg" icon="fa-solid fa-check" /></span>
                        <span v-else>{{ key }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        flash: Boolean,
        showNew: Boolean,
        layout: String,
        keyboardLayout: String,
        greyLetters: String,
        greenLetters: String 
    },
    data: function() { 
        return { 
        config: {
            keyboardRows:{
                "en": {
                    "keys": [ ['q','w','e','r','t','y','u','i','o','p'],
                        ['sp1','a','s','d','f','g','h','j','k','l','sp2'],
                        ['↵','z','x','c','v','b','n','m','del'] ],

                    "spacings":  [ [10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                        [5, 10, 10, 10, 10, 10, 10, 10, 10, 10, 5],
                        [15, 10, 10, 10, 10, 10, 10, 10, 15] ]
                    },
                "sv": {
                    "keys": [ ['q','w','e','r','t','y','u','i','o','p','å'],
                        ['a','s','d','f','g','h','j','k','l','ö','ä'],
                        ['↵','z','x','c','v','b','n','m','del'] ],
                        
                    "spacings":  [ [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10], // multiply by 10 since . not allowed in css classes
                        [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                        [20, 10, 10, 10, 10, 10, 10, 10, 20] ]
                    },
            }   

            }
        }
    },
    mounted: function() {
        this.isMounted = true;
    },
    created: function () {
        window.addEventListener('keydown', (e) => {
          this.onKeyboardInput(e.key);
        });
    
    },
    destroyed: function () {
        document.removeEventListener("keydown", window); // to avoid memory leaks
    },
    methods: {
        onScreenInput: function(key) {
            if (key=="↵") this.onKeyboardInput("Enter");
            else if (key=="del") this.onKeyboardInput("Backspace");
            else this.onKeyboardInput(key);
        },
        onKeyboardInput: function(key) {
            
            this.$emit('keystroke', key);
        },
        checkLetter: function(letter) {

            for (let lettery of this.greyLetters) {
                if (lettery === letter) { 
                    return 'grey'; 
                }  
            }


            for (let lettery of this.greenLetters) { // check the separate maintained set
                if (lettery === letter) { 
                    return 'green'; // for letters on both the exists and doesn't exist list, exists takes precedence
                }  
            }


            
            // IMPORTANT:
            // 'correct' here means that ALL instances of that letter is locked in
            // doesntExist means that there is NO instance whatsoever of the letter
            // no other colors are shown
            
            return 'normal';
        }
    }
}
</script>

<style scoped>
/* keyboards */
.keyboardContainer {
    
    width: 100%;
    max-width: 480px;
    height: 200px;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */

}

.keyboard {
    width: 100%;
    margin: auto;
}

.keyboardRowContainer {
    width: 100%;
}

.keyboardRow {
    display: flex;
    width: 100%;
}

.key {
    display: flex;
    flex: 1;
    height: 60px;
    line-height: 60px;
    font-size: var(--key-size);
    margin: 2px;
    text-align: center;
    border: 1px solid #333;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 4px;
    justify-content: center;
    box-sizing: border-box; 
    transition: background-color 1s;
    
}

.darkMode .key {
    border: 1px solid white;
    
}

.key20 {
    flex: 2.5;
}

.key15 {
    flex: 1.5;
}

.key5 {
    flex: 0.5;
}

.key2 {
    flex: 0.25;
}

.hidden {
    visibility: hidden;
}


/* return key animation */
.flashingKey {
  animation-name: flash-animation;
  animation-duration: 0.4s;
  animation-iteration-count: 4;
  animation-direction: alternate;
  animation-timing-function: linear; 

}

.darkMode .flashingKey {
    animation-name: flash-animation-reversed;
}

@keyframes flash-animation {
  0% {
    background-color: white;
    border: 1px solid black;
    color: black;
  }

  100% {
      background-color: black;
      border: 1px solid white;
      color: white;
  }
}

@keyframes flash-animation-reversed {
  100% {
    background-color: white;
    border: 1px solid black;
    color: black;
  }

  0% {
      background-color: #121212;
      border: 1px solid white;
      color: white;
  }
}

.grey {
  background-color: #9b8f8f;  
}

.darkMode .grey {
   background-color: #464444;  
}


.green {
    background-color: #6699ff;
}

.darkMode .green {
    background-color: #21407e;
}

</style>
