import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
//awesomefonts 
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faBackspace } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'


import Bandwagon from './components/Bandwagon.vue'
//import Eliminator from './components/Eliminator.vue'

Vue.config.productionTip = false;

// awesome fonts
library.add(faChartBar, faInfoCircle, faCog, faTimes, faPlayCircle, faBackspace, faCheck, faExclamationCircle);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueRouter);
Vue.use(VueMeta);
// component: () => import ('./components/Bandwagon.vue') // lazy loading, but in this particular case it increases the size
const routes = [
  { path: '/', meta: { title: 'Bandwagon - A Word Game' }, component: Bandwagon, props: {gameConfigurationProp: 'bandwagon-5-6-en', uiLanguageProp: 'en'}},
  { path: '/en', redirect: '/'},
  { path: '/english',redirect: '/'},
  { path: '/classic',redirect: '/'},
  { path: '/56',redirect: '/'},
  { path: '/74', meta: { title: 'Bandwagon 7x4 - A Word Game' }, component: Bandwagon, props: {gameConfigurationProp: 'bandwagon-7-4-en', uiLanguageProp: 'en'}},
 /* { path: '/85', meta: { title: 'Bandwagon 8x5 - A Word Game' }, component: Bandwagon, props: {gameConfigurationProp: 'bandwagon-8-5-en', uiLanguageProp: 'en'}},*/
 /* { path: '/list', meta: { title: 'Hepyli\'s Word Games Emporium' }, component: Emporium},*/
  { path: '/svenska', meta: { title: 'Bandvagn - Ett ordspel' }, component: Bandwagon, props: {gameConfigurationProp: 'bandwagon-5-6-sv', uiLanguageProp: 'sv'}},
  { path: '/sv', redirect: '/svenska'},
  /*{ path: '/suomi', meta: { title: 'Bändivaunu - sanapeli' }, component: Bandwagon, props: {gameConfigurationProp: 'bandwagon-5-6-fi', uiLanguageProp: 'fi'}},*/
  /*{ path: '/fi', redirect: '/suomi'},*/
  { path: '/bilingia', meta: { title: 'Bilingia' }, component: Bandwagon, props: {gameConfigurationProp: 'bilingia-5-5-fi-sv', uiLanguageProp: 'sv'}},
  { path: '/anagramia', meta: { title: 'Anagramia' }, component: Bandwagon, props: {gameConfigurationProp: 'anagramia-5-3-en', uiLanguageProp: 'en'}},
  /*{ path: '/eliminator', meta: { title: 'Elminator' }, component: Eliminator, props: {gameConfigurationProp: 'eliminator-6-3-en', uiLanguageProp: 'en'}},*/
]

const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  /* It will change the title when the router is change*/
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
