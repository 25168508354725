var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
                            (_vm.isCellAvailable ? ('cell ' + _vm.cellStatus) :'dummy'),
                            (_vm.isCellSelected ? 'selected':''),
                            (_vm.wasJustInput?'wasJustInput':''),
                            ((_vm.requestedInput && !_vm.reportingCell)?'missingInput':''),
                            (_vm.celebrate?'celebrate-opacity celebrate celebrate-'+_vm.row:
                            (_vm.loser?'loser':''))
            ],on:{"click":function($event){return _vm.$emit('selectCell')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }